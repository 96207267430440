button.optim-settings-btn {
    height: 32px;
    padding: 8px 12px;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;

    &>span {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0;
        color: var(--fc-btn-color);
    }

    &:hover {
        &>span {
            color: var(--fc-btn-color);
        }

        border-color: #FB7B34;
    }
}

button.optim-continue-btn {
    box-sizing: border-box;
    height: 32px;
    padding: 8px 12px;
    border-radius: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6467C3;
    border: 1px solid #6467C3;

    &>span {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0;
        color: var(--fc-contrast);
    }

    &:hover {
        background-color: #6467C3 !important;
        border: 1px solid #FB7B34;

        &>span {
            color: var(--fc-contrast);
        }
    }

    &:disabled {
        opacity: 0.2;
        border-color: #6467C3;
        color: var(--fc-contrast);
        background: #6467C3;
        box-shadow: none;
    }
}

.prospects-sections-wrapper {
    padding-inline: 24px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;

    &>.current-portfolio-info {
        flex-basis: 290px;
        height: 350px;
        padding: 16px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 16px;
        border: 1px solid rgba(255, 255, 255, 0.2);
        background-image: url('./background.svg');

        & .portfolio-card-name {
            font-weight: 700;
            font-size: 12px;
            line-height: 12px;
            letter-spacing: 1.2px;

            color: var(--card-name-color);
        }

        & .portfolio-card-value {
            font-weight: 500;
            font-size: 40px;
            line-height: 40px;
            letter-spacing: -0.8px;
            text-align: right;

        }

        & .portfolio-card-text {
            width: 100%;
            margin: 0;
        }


    }

    & .prospect-line-chart {
        flex-basis: calc(100% - 290px);
        padding-left: 24px;
    }

}

.compare-sections-wrapper {
    & .charts-tabs-block {
        flex-basis: 60%;

    }

    & .class-category-block {
        flex-basis: 40%;
    }
}

.optimization-component-wrapper.charts-page {
    justify-content: flex-start;
}

.compare-sections-wrapper {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
    border-top: 1px solid var(--border-color);

    &>.charts-tabs-block {
        flex-basis: 60%;
        border-right: 1px solid var(--border-color);
    }

    &>.class-category-block {
        flex-basis: 60%;
        max-width: 650px;

        &>.class-side-wrap {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            &>.class-side-headers {
                width: 100%;
            }

            &>.class-category-wrapper {
                width: 50%;

                &>.client.table-wrapper {
                    width: 100%;
                }
            }
        }
    }
}