body.dark-theme {
  --card-bcg: transparent;
  --card-bcg-hover: transparent;
  --card-border-color: rgba(255, 255, 255, 0.1);
  --card-border-color-hover: #363231;
  --card-border-color-active: #6C6867;
  --card-name-color: #FFFFFF;
  --card-code-color: #827f7f;

  --switcher-btn-bcg: #2B2726;
  --switcher-btn-color: #FFFFFF;
}

body.light-theme {
  --card-bcg: #fcfcfc;
  --card-bcg-hover: #fcfcfcd7;
  --card-border-color: rgba(255, 255, 255, 0.1);
  --card-border-color-hover: #36312f;
  --card-border-color-active: #6C6867;
  --card-name-color: #000;
  --card-code-color: #2B2726;

  --switcher-color: #2B2726;
  --switcher-btn-bcg: #FCFCFC;
  --switcher-btn-color: #000000;
}

.client.portfolio-card {
  box-sizing: border-box;
  position: relative;
  width: 290px;
  min-width: 290px;
  height: 180px;
  padding: 16px 24px;
  border-radius: 24px;
  border: 1px solid var(--card-border-color);
  background-color: var(--card-bcg);
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.portfolio-info-card {
    margin-top: 24px;
    width: 100%;

    &:not(.unselected) {
      background-image: url('./img/background-wide.svg');
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      background-clip: padding-box;
    }
  }

  &:not(.unselected) {
    background-image: url('./img/background.svg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    background-clip: padding-box;
    border: 1px solid var(--card-border-color-active);
  }

  & .portfolio-card-icons {
    position: absolute;
    top: 10px;
    right: 10px;
    margin: 0;
  }

  & .portfolio-card-name {
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-align: left;
    color: var(--card-name-color);
  }

  & .portfolio-card-code {
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-align: left;
    color: var(--card-code-color);
    margin-left: 8px;
  }

  & .portfolio-card-value {
    font-size: 40px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: -0.02em;
    text-align: right;
    color: var(--card-name-color);
  }

  &.unselected {
    background-color: var(--card-bcg);

    & .portfolio-card-icons {
      visibility: hidden;
    }

    &:hover {
      background: linear-gradient(261.59deg, rgba(43, 39, 38, 0.25) 0%, rgba(36, 32, 31, 0.25) 100%);
      border: 1px solid var(--card-border-color-hover);

      & .portfolio-card-icons {
        visibility: visible;
      }

    }

    & .portfolio-card-string-text {
      color: #4D4D4D;
    }
  }
}