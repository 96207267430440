.table-skeleton {
    display: inline-flex;
    justify-content: center;
    width: 100%;

    &>span {
        width: 98%;
        height: 80px;
        margin-bottom: 16px;
    }
}

.suggestions-table {
    padding-bottom: 20px;
}

.pf-data {
    display: inline-block;
    height: 24px;
    padding: 2px 8px;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;

    &.green-pf-data {
        background: #139e191f;
        color: #139e19;
    }

    &.red-pf-data {
        background: #dc180c1f;
        color: #dc180c;
    }
}

.suggestions-name {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    text-align: left;
    color: #0f0f0f;
}

.suggestions-create-date {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.24px;
    text-align: left;
    color: #525252;
}

.filter-date-title {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    text-align: left;
    color: #0f0f0f;
}

.modal-translation {
    min-height: 375px;
    border-radius: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &>div {
        width: 100%;
    }

    .ant-modal-content {
        padding: 0;
        padding-bottom: 24px;
    }
}

.modal-translation.custom-modal .ant-modal-body {
    padding: 24px;
}

.modal-transaction-header {
    height: 52px;
    justify-content: space-between;
    align-items: center;
    padding: 0 98px 0 48px;
    margin: 0;
}

.ant-btn.cancel-btn {
    height: 32px;

    background: transparent;

    &:hover {
        background-color: transparent !important;

    }
}

.ant-btn.success-btn {
    height: 32px;
    background: #FB7B33;

    &>span {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0;
        color: var(--login-btn-color);
    }

    &:hover {
        background-color: #FB7B33 !important;

    }

    & svg path {
        fill: #0f0f0f;
    }
}

.transaction-tab-wrapper {
    display: flex;
    align-items: center;
    gap: 24px;

    & .transaction-btn.overview-btn {
        height: auto;
    }
}

.modal-action-btn-wrap {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
}

.transaction-table-wrap {
    min-height: 454px;
    overflow-y: auto;
    margin-top: 24px;
}

.transaction-table {
    background-color: var(--bg-main-color);

    &~div p.total-header {
        margin: 0;
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.7px;
        text-transform: uppercase;
        color: var(--fc-label-color);
    }

    &~div p.total-value {
        margin: 0;
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: -0.28px;
        text-align: right;
        color: var(--fc-contrast);
    }
}

.table-buy-sell tbody.ant-table-tbody>tr>td {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.56px;
    letter-spacing: 0.04em;
    text-align: left;
    color: #525252;
}

.table-buy-sell thead.ant-table-thead>tr>th {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.56px;
    letter-spacing: 0.04em;
    text-align: left;
    color: #525252;
}

#filter-config {
    & .filter-date-title {
        padding-left: 16px;
    }

    & .ant-col.ant-form-item-label {
        padding: 0 0 0 16px;
    }
}

button.action-btn.advisor-action-btn.market {
    width: fit-content;
    padding: 0 8px;
}

.ant-select-multiple.ant-select-selection-wrap {
    align-self: center;
}

.type-download {
    & .custom-radio-btn>span {
        flex-shrink: 0;
    }
}

.note-bottom {
    padding-left: 24px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    color: var(--fc-label-color);
}

.ant-table-cell.value {
    white-space: nowrap;
}

.ant-table-cell.date {
    max-width: 120px;
    min-width: 120px;
}

span.transaction-set-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 18.56px;
    letter-spacing: 0.04em;
}

.editable-row.bold>td.ant-table-cell.date {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.56px;
    letter-spacing: 0.04em;
    text-align: left;
    color: #fb7b34;
}

.editable-row>td.ant-table-cell.date {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.56px;
    letter-spacing: 0.04em;
    text-align: left;
    color: #525252;
}

.headerStyle.suggested {
    height: 176px;
}

.headerStyle.onboarding {
    height: 80px;
}

.suggested-tabs-wrapper {
    & .overview-btn {
        border-bottom: none;
    }
}

.search-wrapper {
    position: fixed;
    top: 85px;
    right: 24px;
    z-index: 1001;

    & .filter-btn {
        height: 32px;
        background-color: var(--btn-transparent);
        border: 1px solid var(--border-color);

        &:hover {
            background-color: var(--btn-transparent) !important;
            border: 1px solid var(--border-hover-color);
        }

        & span {
            color: var(--fc-contrast);
        }

        & svg>path {
            fill: var(--fc-prim-color);
        }
    }
}

h1.suggested-portfolios-title {
    display: flex;
    align-items: center;
    height: 80px;
    margin: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.48px;
    color: var(--fc-contrast);
}

.suggestions-table {
    margin-top: 176px;

    & td.ant-table-cell.suggestions-return,
    & td.ant-table-cell.suggestions-volatility,
    & td.ant-table-cell.suggestions-cvar {
        color: var(--fc-prim-color);
    }

    & .action-btn.approve {
        width: auto;
        height: 32px;
        padding-inline: 12px;
        display: flex;
        align-items: center;
        gap: 4px;
        background-color: transparent !important;
        border: 1px solid var(--border-dropdown-color);
        border-radius: 24px;

        &>span {
            color: var(--fc-contrast);
        }
    }

    & .cell-button-wrapper svg>path {
        fill: var(--fc-prim-color);
    }

    & td {
        height: 80px;
    }
}

.account-select-search.suggested {
    margin: 0;

    &::placeholder {
        font-weight: 400;
        font-size: 16px;
        line-height: 100%;
        letter-spacing: 0;

        color: var(--fc-label-color);
    }

    &::-webkit-input-placeholder {
        color: var(--fc-label-color);
    }

    &::-moz-placeholder {
        color: var(--fc-label-color);
    }

    &:-ms-input-placeholder {
        color: var(--fc-label-color);
    }

    &:-moz-placeholder {
        color: var(--fc-label-color);
    }
}

.suggestions-create-date,
.suggestions-approval-date,
.suggestions-approval-date,
.suggestions-approval-date {
    & .create-date {
        font-weight: 500;
        font-size: 14px;
        line-height: 110.00000000000001%;
        letter-spacing: -0.28px;
        color: var(--fc-contrast);
    }

    &>.suggestions-create-time {
        font-weight: 500;
        font-size: 14px;
        line-height: 110.00000000000001%;
        letter-spacing: -2%;
        color: #999999;
    }
}

.ant-modal-header {
    background: none !important;

    &>.ant-modal-title {
        background-color: transparent;
        color: var(--fc-contrast);
    }
}

.ant-modal .ant-modal-content {
    background-color: var(--bg-dropdown-color);
}

.filter-modal {
    & form {
        margin-top: 24px;
    }
}

.modal-header-title {
    margin-top: 0;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.48px;
    color: var(--fc-contrast);
}

.anticon.anticon-close.ant-modal-close-icon svg>path {
    fill: var(--fc-contrast);
}

h2.filter-date-title {
    margin: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0;
    color: var(--fc-label-color);
}

.ant-form-item.custom-datepicker-wrap {
    height: 48px;
    margin: 0;
    margin-bottom: 0;

    &>.ant-row.ant-form-item-row {
        width: 318px;

        & .custom-datepicker {
            width: 100%;
        }
    }

    & .ant-picker {
        height: 48px;
        border-radius: 24px;
        background-color: var(--root-theme-bcg);
        border: 1px solid var(--border-dropdown-color);

        & .ant-picker-input>input {
            height: 48px;
            color: var(--fc-contrast);
            padding-left: 24px;

            &::placeholder {
                color: var(--fc-contrast);
                opacity: 1;
            }
        }

        & .anticon.anticon-calendar {
            &>svg {
                width: 21px;
                height: 21px;

                &>path {
                    fill: var(--fc-label-color) !important;
                }
            }
        }

        & .ant-picker-suffix {
            &>svg {
                width: 21px;
                height: 21px;

                &>path {
                    fill: var(--fc-label-color) !important;
                }
            }
        }
    }

    & .ant-picker-clear {
        color: var(--fc-label-color) !important;
    }
}

.filter-divider {
    margin-top: 2px;
    margin-bottom: 20px;

    &.ant-divider {
        border-color: #423a38 !important;
    }
}

.ant-col.ant-form-item-label>label {
    color: var(--fc-label-color);
}

span.ant-select-selection-item>span.ant-select-selection-item-content {
    display: flex !important;
    align-items: center;
}

.filter-control-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0;
    color: var(--fc-label-color) !important;
}

div.ant-empty.ant-empty-normal {
    background-color: transparent !important;
    border: none;

    &>.ant-empty-description {
        color: var(--fc-secondary-light);
    }

    & .ant-empty-image>svg path {
        fill: var(--fc-secondary-light);
    }
}

.ant-table-wrapper .ant-table-tbody>tr>td {
    border-bottom: 1px solid var(--border-hover-color);
}

.ant-table-thead>tr>th {
    border-bottom: 1px solid var(--border-hover-color) !important;
}

.custom-modal {
    & .ant-modal-body {
        box-sizing: border-box;
        min-height: 229px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    & button.ant-modal-close {
        width: 40px;
        height: 40px;
        border: 1px solid var(--border-color);
        border-radius: 50%;
        top: 24px;
        right: 24px;

        &:hover {
            border-color: var(--border-hover-color);
        }
    }

    .modal-text-wrapper {
        margin-top: 24px;
    }

    .modal-question-text {
        font-weight: 500;
        font-size: 14px;
        line-height: 110.00000000000001%;
        letter-spacing: -0.48px;
        color: var(--fc-label-color);
        text-align: left;
    }

    .modal-item-name {
        font-weight: 500;
        font-size: 14px;
        line-height: 110.00000000000001%;
        letter-spacing: -0.48px;
        color: var(--fc-contrast);
        text-align: left;

    }

    .modal-footer-btn-wrapper {}
}

.transaction-table {
    & th.ant-table-cell.name span {
        color: var(--fc-contrast);
    }

    & th:not(.name) .ant-table-column-sorters {
        justify-content: flex-end;
    }

    & tr.ant-table-row>td.ant-table-cell {
        font-weight: 700 !important;
        font-size: 12px !important;
        line-height: 12px !important;
        letter-spacing: 1.2px !important;
        color: var(--fc-contrast);

        &.name {
            font-weight: 500 !important;
            font-size: 14px !important;
            line-height: 110.00000000000001% !important;
            letter-spacing: 0.28px !important;
        }

    }

    // & tr:not(.bold)>td.ant-table-cell:not(.date) {

    // }
}