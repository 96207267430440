.custom-sort-icon {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 3.4px;
    background-color: var(--bg-dropdown-color);
    border-radius: 4px;
    margin-left: 16px;
    width: 16px;
    height: 16px;

    & svg.selected-sort path {
        fill: #FB7B34;
    }
}