body.dark-theme {
    --icon-color: #7E716D;
    --input-border-color: #423A38;
    --input-border-color-hover: #423A38;
    --input-background-color: transparent;
    --input-background-color-hover: transparent;
}

body.light-theme {
    --icon-color: #000000;
    --input-border-color: #E6E6E6;
    --input-border-color-hover: #ff9c5e;
    --input-background-color: transparent;
    --input-background-color-hover: transparent;
}

.instrument-button-wraper {
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
    align-items: center;
    gap: 8px;
}

.ant-select.ant-select-outlined.custom-search-input {
    width: 100%;
    max-width: 360px;
    height: 48px;
    border-radius: 24px;
    font-family: 'Aeonik Quant';
    font-size: 16px !important;
    font-weight: 400;
    line-height: 18.56px;
    color: #0F0F0F;
    border: 1px solid #d9d9d9;

}

.custom-input-wrap {
    & input {
        border-color: var(--input-border-color);
        background-color: var(--input-background-color);
        color: var(--fc-secondary);

        &:focus {
            border-color: var(--input-border-color);
            background-color: var(--input-background-color);
            color: var(--fc-secondary);
        }

        &+svg>path {
            fill: var(--icon-color)
        }

        &:hover {
            border-color: var(--input-border-color-hover);
            background-color: var(--input-background-color-hover);
        }
    }
}