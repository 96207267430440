.advisor-wrapper {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.wait-activated-status {
  color: rgba(0, 0, 0, 0.3);
}

.submit-form-wrapper {
  margin-top: 70px;
}

.disabled-cell {
  opacity: 0.3;
}

.advisor-container {
  padding: 10px 24px;
  margin-top: 24px;
}

.status-advisor {
  display: inline-block;
  border-radius: 8px;
  padding: 3px 18px;
  height: 24px;
  font-family: Aeonik Quant;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;


  &.yellow {
    color: #E59A00;
    background-color: #FFAB001F;
  }

  &.green {
    color: #139E19;
    background-color: #139E191F;
  }

  &.red {
    color: #DC180C;
    background-color: #DC180C1F;
  }

  &.purple {
    color: #6367C3;
    background-color: #6367C31F;
  }
}

button.action-btn {
  width: 107px;
  height: 40px;
  padding: 0px 32px;
  border-radius: 24px;
  border: 1px solid #0F0F0F;
  display: flex;
  justify-content: center;
  align-items: center;

  &.advisor-action-btn {
    width: 107px;
    height: 40px;

  }

}

.filter-counter {
  display: flex;
  align-items: center;
  min-width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: #FB7B34;
  font-family: Aeonik Quant;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.24px;
  color: #0F0F0F;
  margin-left: 8px;
  margin-right: 8px;
  padding: 0 4px;
  padding-top: 2px;
}

.filter-btn {
  padding: 6px 24px;
  height: 36px;
  border: 1px solid #0F0F0F;
  border-radius: 24px;
  background-color: #FCFCFC;
  display: flex;
  justify-content: center;
  align-items: center;

  &>span {
    font-family: Aeonik Quant;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;

  }
}

.modal-advisor-text {
  font-family: Aeonik Quant;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.56px;
  text-align: center;
  color: #262626;
  margin: 0;
}

.modal-advisor-name {
  font-family: Aeonik Quant;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.56px;
  color: #262626;
  text-align: center;
  margin: 0;
  margin-top: 36px;
}

.modal-advisor-btn-wrapper {
  justify-content: center;
  gap: 16px;
  margin-top: 50px;
  margin-bottom: 52px;
}

.modal-action-btn.delete-btn {
  background-color: #DC180C;
  color: #FCFCFC;
}

.ant-pagination-options-size-changer .rc-virtual-list-holder-inner {
  background-color: #FCFCFC;
}

.headerStyle.contrast {
  & .overview-btn {
    color: var(--fc-contrast);

    &:hover {
      color: var(--fc-contrast);

    }
  }
}

.subheader-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0;
}

.subheader-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.4px;
  color: var(--fc-contrast);
  margin: 0;
}

.subheader-row {
  & .filter-btn {
    height: 32px;
    background-color: var(--btn-transparent);
    border: 1px solid var(--border-color);

    &:hover {
      background-color: var(--btn-transparent) !important;
      border: 1px solid var(--border-hover-color);
    }

    & span {
      color: var(--fc-contrast);
    }

    & svg>path {
      fill: var(--fc-prim-color);
    }
  }

  & input.account-select-search {
    color: var(--fc-contrast);
  }

}

.overview-btn.header-btn {
  height: 80px;
}

button.action-btn.users-action {
  width: auto;
  height: 32px;
  padding: 0 12px 0 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0;
  border: 1px solid var(--border-dropdown-color);
  color: var(--fc-contrast);

  &:hover {
    border: 1px solid var(--border-dropdown-color-light) !important;
    color: var(--fc-contrast) !important;

  }
}

tr>td.ant-table-cell {
  height: 80px;
}

.portfolio-details-btn.optimise-btn.user-btn {
  width: auto;
  height: 32px;
  padding: 0 12px 0 8px;
}

.edit-user-wrapper {
  width: 652px;
  margin: 0 auto;
  padding-bottom: 48px;

  & .edit-usere-form {
    margin: 0;
    padding: 0;
    width: 100%;

  }
}

.new-user-card {
  padding: 16px;
  margin-top: 24px;
  margin-bottom: 48px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 180px;
  border-radius: 16px;
  background:
    center / cover no-repeat url('./img/bcg-card.svg'),
    linear-gradient(180deg, #FF9FEE 11.14%, #77D5EC 83.42%);

  &>.new-user-card-name {
    font-weight: 500;
    font-size: 32px;
    line-height: 32px;
    letter-spacing: -0.64px;
    text-align: right;
    color: var(--bg-secondary-color);
    margin: 0;
    margin-bottom: 16px;
  }

  &>.new-user-card-note {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    color: var(--fc-label-color);
    margin: 0;
    text-align: right;
  }
}

.edit-user-form-row {
  justify-content: space-between;
  flex-wrap: nowrap;
  gap: 16px;

  & .column {
    flex-basis: 50%;

    &.full-width {
      flex-basis: 100%;
    }

    & input {
      border-color: var(--border-dropdown-color);

      &:hover {
        border-color: var(--border-dropdown-color-light);
      }
    }

    & .ant-select.ant-select-outlined.custom-select {
      border-color: var(--border-dropdown-color);

      &:hover {
        border-color: var(--border-dropdown-color-light);
      }
    }

    &.identification-number {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      gap: 10px;

      & input {
        width: 268px !important;
      }

    }


  }


}

.edit-user-form-row .ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
  background: transparent !important;
}

.buket-delete-btn {
  position: absolute;
  top: 33px;
  right: 17px;
  cursor: pointer;

  & path {
    fill: var(--fc-prim-color);
  }
}

.phone-add-button {
  padding: 0 8px 0 12px;
  height: 32px;
  border: 1px solid var(--border-dropdown-color);
  border-radius: 24px;
  background: transparent !important;
  color: var(--fc-contrast);

  &:hover {
    border: 1px solid var(--border-dropdown-color-light);
    background: transparent !important;
    color: var(--fc-contrast) !important;
  }

  & svg path {
    fill: var(--fc-prim-color);
  }
}

.users-phones-col {
  & .phone-number-wrapper .select-phonecode {
    background-color: transparent !important;
    border: 1px solid var(--border-dropdown-color);
    border-right: none !important;

    & input {

      color: var(--fc-contrast) !important;
    }
  }

  & .phone-number-wrapper>input {
    border: 1px solid var(--border-dropdown-color);
    border-left: none !important;
  }
}