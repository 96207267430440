.category-switcher-wrapper {
    height: 40px;
    width: max-content;
    max-width: 570px;
    border-radius: 24px;
    display: flex;
    align-items: center;
    border: 1px solid var(--border-dropdown-color);
    margin-bottom: 16px;
    margin-left: auto;
    margin-right: auto;
}

.category-switcher-btn {
    height: 32px;
    max-width: 140px;
    padding-inline: 16px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    white-space: normal;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;

    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.24px;
    text-transform: uppercase;

    color: var(--fc-third-color);
    cursor: pointer;

    &.active {
        background-color: var(--fc-contrast);
        border-radius: 24px;
        color: var(--fc-btn-color);

    }
}

.onboarding-kyc .custom-scroll {
    overflow-x: auto;
    width: 570px;
    margin-bottom: 12px;

    & .category-switcher-wrapper {
        max-width: none;
    }

    & .category-switcher-btn {
        min-width: 100px;
        max-width: 170px;
    }
}

.custom-scroll {
    width: 100%;
    white-space: nowrap;
    scrollbar-color: #7E716D #393332;
    scrollbar-width: thin;

}

/* Кастомизация скроллбара только для .custom-scroll */
.custom-scroll::-webkit-scrollbar {
    height: 2px !important;
}

.custom-scroll::-webkit-scrollbar-thumb {
    background: #7E716D;
    border-radius: 1.5px;
}

.custom-scroll::-webkit-scrollbar-track {
    background: #393332;
}