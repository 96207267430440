.optimise-dark-btn {
    height: 24px;
    padding: 0 12px;
    padding-top: 1px;
    background-color: #6467C3;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    border: 1px solid #6467C3 !important;
    border-radius: 24px;

    &>span {
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: #FFFFFF;
    }

    &:hover {
        background-color: #6467C3 !important;
        border: 1px solid #FB7B34 !important;
    }
}