.portfolio-optimization {
  min-height: 100vh;
  height: 100%;
  padding: 48px 0;
  max-width: 1448px;
  width: 100%;

  & .ant-steps-item-icon {
    margin-inline-start: 50px !important
  }


  &-sub-header {
    font-family: Open Sans;
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: 0px;
    text-align: left;
    padding: 6.5px 24px;
    border-bottom: 1px solid #DEDEDE
  }

  &-form {
    padding: 25px 22px;

    & .portfolio-form {
      &-radio {
        & span.ant-radio span.ant-radio-inner {
          border-radius: 50%;
          border-color: #799AA9;
          background-color: transparent;

          &::after {
            background-color: #248926;
          }
        }

        & span {
          color: #626363;
          font-size: 12px;
          font-family: 'Open Sans';
          letter-spacing: 0.2px;
        }
      }
    }

    & .detail-container {
      width: 100%;
    }
  }

  .portfolio-optimization-heading {
    font-size: 38px;
    font-family: "Lato";
    text-align: center;
    margin-top: 48px;
    margin-bottom: 48px;
  }

  .ant-steps-item {
    .ant-steps-item-title {
      font-size: 12px;
      font-family: "Lato";
      color: #799aa9 !important;
      line-height: 14px;
    }

    .ant-steps-item-icon {
      border-color: #bbd1dc;
      line-height: 28px;

      .ant-steps-icon {
        color: #799aa9;
        font-size: 14px;
        font-family: "Lato";
      }
    }

    .ant-steps-item-tail {
      &:after {
        background: #bbd1dc;
      }
    }
  }

  .ant-steps-item-active {
    .ant-steps-item-title {
      color: #000 !important;
    }

    .ant-steps-item-icon {
      background: #305572;
      border-color: transparent;

      .ant-steps-icon {
        color: #fff;
      }
    }
  }


}

.portfolio-optimization-steps {
  width: 80%;
  margin: 0 auto;
  position: fixed;
  top: 10px;
  right: 110px;
  z-index: 1300;
}

.portfolio-optimization-steps-button {
  width: 160px;
  height: 40px;
  font-size: 15px;
  font-family: "Lato";
}

.portfolio-optimization-steps-cancel {
  border-top: 1px solid #dde9ea;
  padding-top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-items: center;
  gap: 24px;
  width: 100%;
  max-width: 480px;
  margin: 38px auto;
  cursor: pointer;

  .portfolio-optimization-steps-cancel-text {
    font-size: 14px;
    line-height: 14px;
    font-family: "Lato";
    font-weight: 500;
    color: #666666;
  }
}

.portfolio-optimization-actions {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  max-width: 480px;
  margin: 24px auto 0 auto;
}


.portfolio-container {
  background: #ffffff;
  box-shadow: none;
  border-radius: 8px;
  border: 1px solid #dde9ea;
  padding: 11px 22px;
  width: 100%;


  .portfolio-container-title {
    font-size: 18px;
    font-weight: 600;
    color: #000;
    font-family: "Lato";
    border-bottom: 1px solid #dde9ea;
    padding-bottom: 16px;
    padding-left: 32px;

    &.min-max {
      border-bottom: none;
    }
  }

  .portfolio-container-description {
    padding-left: 32px;
    padding-right: 32px;
    font-family: "Lato";
    font-size: 14px;
  }
}

.portfolio-form-subheading {
  font-size: 14px;
  font-weight: 600;
  color: #000;
  font-family: "Lato";
  margin-left: 32px;
  margin-bottom: 16px;
}

.portfolio-form-container {
  margin-top: 24px;
}

.portfolio-form-container-part {
  display: flex;
  align-items: center;
  padding: 0 32px;

  &.bias:not(:first-child) {
    margin-bottom: 6px;
  }
}

.portfolio-form-input-container {
  display: flex;
  flex-direction: column;
  margin-right: 24px;
  width: 100%;
  max-width: 338px;

  &.checkbox {
    flex-direction: row;
    align-items: center;
    margin-top: 18px;
  }

  &.gap {
    margin-left: 32px;
    margin-top: 16px;
  }

  &.bias-checkbox,
  &.fees-checkbox {
    margin-bottom: 24px;
    margin-left: 32px;
  }

  &.bias-percentage {
    max-width: 120px;
  }

  &.second-block {
    max-width: 459px;
    margin-bottom: 16px;
  }
}

.portfolio-form-label {
  color: #666666;
  font-size: 12px;
  font-weight: 600;
  font-family: "Lato";
  margin-bottom: 4px;

  &.bias-checkbox-label,
  &.fees-checkbox-label {
    font-size: 14px;
    font-weight: 400;
    color: #000;
    cursor: pointer;
  }

  &.bias-label {
    margin-left: 32px;
    margin-bottom: 4px;
    display: block;
  }
}

.portfolio-form-input {
  max-width: 338px;
  width: 100%;
  border: 1px solid #dde9ea;
  border-radius: 5px;
  height: 40px;
  padding: 12px;

  &.second-block {
    max-width: 459px;
  }

  &.bias {
    max-width: 120px;
  }

  &::placeholder {
    font-size: 12px;
    font-weight: 400;
    font-family: "Lato";
    color: #8a8a8a;
  }

  &.number::-webkit-outer-spin-button,
  &.number::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
  }
}

.portfolio-form-select {
  max-width: 459px;
  width: 100%;
  border: 1px solid #dde9ea;
  border-radius: 5px;
  height: 40px;
  font-size: 12px;
  font-weight: 400;
  font-family: "Lato";
  color: #8a8a8a;
}

.portfolio-form-checkbox {
  border: 1px solid #666666;
  border-radius: 3px;
  margin-right: 12px;
  width: 16px;
  height: 16px;
  cursor: pointer;

  &:checked {
    -webkit-appearance: none;
    appearance: none;
    background-color: #fff;
    position: relative;

    &:before {
      content: "\2713";
      font-size: 1em;
      color: #666666;
      position: absolute;
      right: 1px;
      top: -5px;
    }
  }
}

.portfolio-form-checkbox-label {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  font-family: "Lato";
}

.add-investor-bias {
  width: 180px;
  height: 25px;
  font-size: 12px;
  margin-left: 32px;
  margin-top: 32px;
}

#pdf-part-6 .highcharts-series-1>path.highcharts-area {
  fill: rgba(48, 85, 114, 0.25);
}

#pdf-part-6 .highcharts-series-0>path.highcharts-area {
  fill: rgba(201, 71, 9, 0.25);
}

.finish-cancel-button,
.finish-ok-button {
  width: 40%;
  height: 40px;
  border-radius: 20px;
  margin-right: 16px;
  border: 1px solid #dde9ea;
  background-color: #305572;
  color: #ffffff;
  font-size: 15px;
  font-family: "Lato";
}

.portfolio-form-select.opt-select.percentage {
  & .ant-input-number-input-wrap {
    display: inline-block;
    width: fit-content;

    &::after {
      content: "%";
    }

    &>.ant-input-number-input {
      max-width: 47px;
    }
  }

  &.blank {
    & .ant-input-number-input-wrap>.ant-input-number-input {
      width: 37px;
    }
  }

  &.one-dig {
    & .ant-input-number-input-wrap>.ant-input-number-input {
      width: 37px;
    }
  }

  &.two-dig {
    & .ant-input-number-input-wrap>.ant-input-number-input {
      width: 45px;
    }
  }

  &.three-dig {
    & .ant-input-number-input-wrap>.ant-input-number-input {
      width: 54px;
    }
  }
}

.fees-input.percentage {
  & .ant-input-number-input-wrap {
    width: fit-content;

    &::after {
      content: "%";
    }

    &>input {
      padding-left: 10px;
      padding-right: 1px;
    }
  }

  &.blank {
    & .ant-input-number-input-wrap>.ant-input-number-input {
      width: 140px;
    }
  }

  &.one-dig {
    & .ant-input-number-input-wrap>.ant-input-number-input {
      width: 37px;
    }
  }

  &.two-dig {
    & .ant-input-number-input-wrap>.ant-input-number-input {
      width: 45px;
    }
  }

  &.three-dig {
    & .ant-input-number-input-wrap>.ant-input-number-input {
      width: 53px;
    }
  }
}

.portfolio-constraints .portfolio-form-select.opt-select.percentage {
  &.blank {
    & .ant-input-number-input-wrap {
      width: 115px;
    }

    & .ant-input-number-input-wrap>.ant-input-number-input {
      width: 100px;
      max-width: 100px;
    }
  }

  & .opt-portfolio-form-col {
    padding-right: 25px;
  }
}

.portfolio-constraints .percentage:not(.dig) {
  & .ant-input-number-input-wrap {
    width: fit-content;

    &::after {
      content: "%";
    }

    &>input {
      padding-right: 1px;
    }
  }

  &.dig1 {
    & .ant-input-number-input-wrap>.ant-input-number-input {
      width: 22px;
    }
  }

  &.dig2 {
    & .ant-input-number-input-wrap>.ant-input-number-input {
      width: 30px;
    }
  }

  &.dig3 {
    & .ant-input-number-input-wrap>.ant-input-number-input {
      width: 38px;
    }
  }

  &.dig4 {
    & .ant-input-number-input-wrap>.ant-input-number-input {
      width: 46px;
    }
  }

  &.dig5 {
    & .ant-input-number-input-wrap>.ant-input-number-input {
      width: 54px;
    }
  }
}

.optimization-component-wrapper {
  display: flex;
  flex-direction: column;
  padding-top: 72px;
  justify-content: space-between;
  min-height: calc(100vh - 72px);
}

.optimization-component-wrapper.transaction-page {
  justify-content: flex-start;

  & .transaction-tabs-wrapper {
    height: 80px;
    align-items: center;
  }

  &>.transaction-inner-wrapper {
    width: 100%;
    max-width: 1640px;

    & .portfolio-table {
      width: 100%;

      & td.ant-table-cell.share {
        color: var(--fc-prim-color);
      }

      & tr.ant-table-row>td {
        height: 80px;
      }
    }
  }
}