td.exclude-fee.holdings-fee>p {
    color: var(--fc-prim-color) !important;
}

.holdings-assetclass-cell-category,
.holdings-assetclass-cell-asset {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    color: var(--fc-third-color);
    margin: 0;
}