.custom-menu-wrapper {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    background-color: transparent;

    & .custom-menu-btn {
        width: 100%;
        background: transparent;
        justify-content: flex-start;

        &>span {
            font-weight: 500;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: -2%;

            color: var(--fc-label-color);
        }
    }

    & .submenu-wrapper {
        max-height: 300px;
        background-color: var(--bg-dropdown-color);
        transition: max-height 1s;
        overflow: hidden;

        &.hidden {
            max-height: 0;
            overflow: hidden;
            transition: max-height 1s;
        }

    }

    & .custom-menu-btn {
        justify-content: flex-start;

        &:not(.selected) {
            gap: 0;
        }

        & .menu-icon-wrap {
            display: flex;
            align-items: center;

            &>svg {
                opacity: 0;
            }
        }

        &:hover .menu-icon-wrap>svg {
            opacity: 1;
        }

        & span {
            display: block;
            white-space: normal;
            overflow-wrap: break-word;
            word-wrap: break-word;
        }

    }

    &.collapsed .client-menu-btn {
        padding: 0;
        padding-left: 26px;
    }

    & .main-menu-btn-wrapper {
        min-height: 64px;
        display: flex;
        align-items: center;
        width: 100%;
        flex-wrap: nowrap;
        padding-right: 24px;
        cursor: pointer;
    }

    & .custom-menu-btn {
        min-height: 64px;
    }

    & .button-title-wrap {
        display: flex;
        flex-wrap: wrap;

        &>p {
            margin: 0;
            width: 100%;
        }
    }

    & svg.visible-list {
        transform: rotate(180deg);
        transition: transform 0.3s ease-in-out;

    }

    & svg.hidden-list {
        transition: transform 0.3s ease-in-out;
    }

    &.collapsed {
        & button.selected>svg path {
            fill: var(--fc-prim-color);
        }
    }

}