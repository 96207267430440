.new-account-wrapper {
    width: 100%;
    background-color: var(--bg-main-color);
    padding-top: 80px;
    min-height: 100vh;
    overflow-y: auto;
}

.account-form {
    width: 652px;
    margin: 24px auto;
}

.new-account-card {
    box-sizing: border-box;
    width: 100%;
    height: 180px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 16px;
    border: 1px solid;

    padding: 24px 16px;
    background:
        center / cover no-repeat url('./bcg.svg'),
        linear-gradient(180deg, #FF9FEE 11.14%, #77D5EC 83.42%);
}

.new-account-card-title {
    font-weight: 500;
    font-size: 32px;
    line-height: 32px;
    letter-spacing: -0.64px;
    text-align: right;
    color: var(--fc-btn-color);
    text-align: right;
    margin: 0;
    margin-bottom: 16px;
}

.account-card-promt {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0;
    color: var(--fc-label-color);
    text-align: right;
    margin: 0;
}

.account-card-rb-label {
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0;
    color: var(--fc-label-color);
    text-align: left;
    margin: 0;
}

.newaccount-name-row {
    width: 100%;

}

.new-account-divider {
    margin: 24px 0;
    background-color: var(--border-color);
}

.ant-form-item.full-width {
    width: 100%;
}

.account-form {
    & .full-width {
        & input {
            border-color: var(--border-dropdown-color);

            &:hover {
                border-color: var(--border-dropdown-color-light);
            }

            &.ant-input-status-error {
                background-color: transparent !important;

            }

            &::placeholder {
                color: var(--fc-label-color);
            }
        }
    }

    & .onboarding-textarea-purpose {
        & textarea {
            border-color: var(--border-dropdown-color);
            background-color: transparent !important;

            &:hover {
                border-color: var(--border-dropdown-color-light);
                background-color: transparent !important;
            }

            &::placeholder {
                color: var(--fc-label-color);
            }

        }
    }
}