.clients-container {
  margin-bottom: 40px !important;
  margin-top: 140px;

  &.detail-container {
    padding: 16px 0;
    width: 480px;
  }

  & .select-clients {
    width: calc(100% - 32px);
    margin: 16px 16px 100px 16px;
  }
}

.clients-header {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.2px;
  border-bottom: 1px solid #DDE9EA;
  padding-bottom: 16px;
  padding-left: 16px;
}

.new-client-wrapper {
  height: 100%;
}

.portfolio-summary-container,
.instrument-wrapper,
.advisor-wrapper,
.portfolio-wrapper,
.order-instructions-wrapper,
.report-wrapper,
.client-profile-wrapper,
.crete-client-acc,
.portfolio-optimization,
.assetclass-wrapper,
.crete-client-account {
  width: 100%;
  position: relative;
  min-height: 100vh;
  max-height: 100vh;
  overflow-y: auto;
  background-color: var(--bg-main-color);
}

.crete-client-acc {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

.client-profile-container {
  min-width: 600px;
  width: 100%;
  margin: 24px;
}

.current-portfolio-Holdings {
  width: 100%;
  box-sizing: border-box;
}

.portfolio-title-wrapper,
.portfolio-details-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  padding: 35px 30px 24px 24px;

  &.standing {
    padding: 24px;
  }

  &>.portfolio-container-title {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 27.84px;
    width: fit-content;
    max-width: 300px;
  }
}

.opt-title-wrap {
  & .portfolio-container-title {
    margin: 0;
    font-size: 24px;
    font-weight: 700;
    line-height: 27.84px;
    width: fit-content;
    max-width: 300px;
  }
}


.portfolio-details-data-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  gap: 24px;
  padding: 0px 24px;
}

.portfolio-details-data {
  border: 1px solid #0F0F0F;
  border-radius: 24px;
  padding: 16px 24px;
  height: 100%;
  background-color: #FCFCFC;

  &-left {
    height: auto;
    min-width: 265px;
    flex-basis: 23%;
  }

  &-right {
    height: auto;
    flex-basis: 77%;

    &>.portfolio-details-data {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
    }

    & .details-data-col {
      flex-basis: 20%;

      &>.details-create {
        font-family: Aeonik Quant;
        font-size: 16px;
        font-weight: 500;
        line-height: 18.56px;

        height: 24.59px;
        margin: 0 0 26px 0;
      }

      &>.details-date {
        font-family: Aeonik Quant;
        font-size: 20px;
        font-weight: 500;
        line-height: 23.2px;
        letter-spacing: 0.04em;

        margin: 0 0 8px 0;
      }

      &>.details-createdBy {
        font-family: Aeonik Quant;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.24px;

        margin: 0;
      }

      &.fbasis-22 {
        flex-basis: 24.5%;
      }

      &.fbasis-18 {
        flex-basis: 17%;
      }
    }
  }
}

.portfolio-container-title,
.portfolio-details-title {
  font-family: 'Aeonik Quant';
  font-size: 24px;
  font-weight: 700;
  line-height: 27.84px;
  text-align: center;


  &.standing {
    margin: 0;
  }
}

.portfolio-details-title {
  text-align: left;
  margin: 0;
}

.portfolio-details-name {
  font-family: 'Aeonik Quant';
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #0F0F0F;
  margin: 0;
  text-align: left;
}

.portfolio-details-amount {
  font-family: 'Aeonik Quant';
  font-size: 20px;
  font-weight: 500;
  line-height: 23.2px;
  letter-spacing: 0.04em;
  color: #0F0F0F;
  margin: 24px 0 0;
  text-align: left;
}


.portfolio-add-btn {
  display: flex;
  align-items: center;
  padding: 4px 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;

}

.portfolio-list-wrapper {
  overflow-x: auto;
  gap: 24px;
  padding: 0 24px 16px;
}

.client.portfolio-list-wrapper {
  overflow-x: auto;
  gap: 24px;
  padding: 0 24px 16px;
  min-height: 212px;
}

.portfolio-card {
  position: relative;
  width: 268px;
  min-width: 268px;
  // height: 127px;
  padding: 16px 24px;
  border-radius: 24px;
  border: 1px solid #0F0F0F;
  background-color: #0F0F0F;
  color: #FEFEFE;
  cursor: pointer;

  &.unselected {
    background-color: #FCFCFC;

    &>.portfolio-card-name {
      color: #0F0F0F;
    }

    &>.portfolio-card-value,
    &>.portfolio-card-code {
      color: #0F0F0F;
    }

    & .portfolio-card-string-text {
      color: #4D4D4D;
    }
  }
}

.portfolio-card-icons {
  position: absolute;
  top: 0;
  right: 24px;
}

.portfolio-card-string {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  margin: 0;

  &-dig {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    letter-spacing: 0.04em;
    color: #008005;
    margin-right: 5px;
  }

  &-text {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.24px;
    color: #B3B3B3;
  }
}

.portfolio-card-name {
  width: calc(100% - 40px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  margin: 0;
}

.portfolio-card-value {
  font-size: 20px;
  font-weight: 500;
  line-height: 23.2px;
  letter-spacing: 0.04em;
  text-align: left;
  margin: 0;
  margin-bottom: 8px;
}

.portfolio-card-code {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.24px;
  text-align: left;
  color: #CCCCCC;
  margin: 3px 0 16px;
}

.portfolio-card-deprecated {
  display: inline-block;
  height: 20px;
  padding: 0 8px;
  background: #6367C31F;
  border-radius: 8px;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: #6367C3;
  margin-left: 8px;
}

.overview-tabs-wrapper {
  padding: 0;
  width: 100%;
}

.tabs-btn-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-end;
}

.tabs-tab-wrapper {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: flex-start;
}

.overview-btn {
  display: flex;
  gap: 24px;
  align-items: center;
  padding: 0;
  height: 78px;
  color: var(--fc-primary);
  border-radius: 0;

  &>span {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -2%;
  }

  &:hover>span {
    color: var(--fc-contrast);
  }

  &.ant-btn-active {
    border-bottom: 2px solid var(--fc-contrast);

    &>span {
      color: var(--fc-contrast);
    }
  }

  &.active {

    &>span {
      color: var(--fc-contrast);
    }
  }
}

button.optimise-btn {
  font-family: Aeonik Quant;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  padding: 10px 32px;
  color: #0F0F0F;
  background-color: #FB7B34;

  &>span {
    color: #0F0F0F;
  }

  &[disabled] {
    background-color: #FAE5D8;
  }

  &:hover {
    background: #FB7B34 !important;
    border: 1px solid #0F0F0F;
  }

  &.optimise-btn-big {
    width: 100%;
    padding: 10.57px 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: #0F0F0F;
  }
}

button.edit-btn {
  font-family: Aeonik Quant;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;

  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 32px;
  border: 1px solid #0F0F0F;
  border-radius: 24px;
  color: #0F0F0F;

  &[disabled] {
    background-color: #4d4d4d94;
  }

  &:hover {
    background-color: #00000056 !important;
    color: #ebecf2b7 !important;
  }
}

.standing-container {
  width: 100%;
  // min-height: 434px;
  display: flex;
  flex-wrap: nowrap;
  gap: 24px;
  padding: 0 24px;
  justify-content: space-between;
}

.chart-wrapper,
.asset-class-wrapper {
  width: 67%;
}

.rebalance-wrapper {
  width: 33%;
  padding: 24px 24px 48px 24px;

  &>div {
    height: 100%;
  }
}

.chart-wrapper {
  padding: 20px;
}

.export-btn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #232323;
}

.standing-title {
  margin: 0;
  margin-bottom: 6px !important;
  font-size: 28px;
  font-weight: 500;
  line-height: 32.48px;
  letter-spacing: 0.04em;
  text-align: left;
  color: #2B283D;
}

.standing-date {
  margin-bottom: 24px !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.24px;
  text-align: left;
  color: #808080;
}

.standing-chart {
  width: 100%;
  height: 241px;
  background-image: url('../../assets/Chart.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.standing-chart-footer {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
}

.standing-chart-cell {
  padding: 0 24px;
  border-left: 2px solid #64969E;
}

.standing-chart-footer {
  margin: 16px 0;
}

.standing-chart-cell-title {
  margin: 0;
  margin-bottom: 0px !important;
  font-size: 16px;
  font-weight: 500;
  line-height: 18.56px;

}

.standing-chart-cell-data {
  margin: 0;
  margin-bottom: 0px !important;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.2px;
  letter-spacing: 0.04em;
  text-align: left;
}

.rebalance-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 23.2px;
  text-align: left;
  margin: 0;
}

.rebalance-cell-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.24px;
  text-align: left;
  margin: 0;
  color: #808080;
}

.rebalance-color-data {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  width: 132px;
  height: 24px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  &.green {
    background: #139E191F;
    color: #3A8F3D;

  }

  &.red {
    background: #FEEDED;
    color: #F14D4D;
  }
}

.portfolio-card-skeleton {
  width: 268.75px !important;
  height: 150px !important;
  border-radius: 12px !important;
  padding: 10px !important;
}

.chart-pie-title {
  width: 100%;
  margin: 0;
  padding: 24px;
  font-family: Aeonik Quant;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.2px;

  color: #0F0F0F;
}

.category-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-bottom: 14px;
}

.category-data-wrapper {
  margin-top: 47px;
  padding: 24px;
}

.category-cell-first {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-basis: 40%;
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.24px;
  color: #0F0F0F;
  white-space: nowrap;
}

.category-cell-second {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.24px;
  letter-spacing: 0.04em;
  color: #F0A67A;
  flex-basis: 20%;
  text-align: right;
  white-space: nowrap;
}

.category-cell-third {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.24px;
  letter-spacing: 0.04em;
  color: #222220;
}

.category-cell-third {
  flex-basis: 40%;
  text-align: right;
  white-space: nowrap;
}

.asset-class-title,
.asset-class-data {
  margin-top: 30px;
  border-top: 1px solid #777777;
  border-bottom: 1px solid #777777;
  padding: 16px 10px;
  background-color: #F9FAFB;

  &>div {

    font-family: Aeonik Quant;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.56px;
    color: #525252;
  }

  &.is-odd {
    background-color: transparent;
  }

  &-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.56px;
    color: #0F0F0F;
  }

  &-value {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.56px;
    letter-spacing: 0.04em;
    color: #525252;
    white-space: nowrap;
  }

  &-value {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  &-allocation {
    display: flex;
    align-items: center;
    justify-content: center;

    &-cell {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 24px;
      padding: 0 8px;
      border-radius: 8px;
      color: #139E19;
      background: #139E191F;

    }
  }
}

.asset-class-title-name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}


.asset-sort {
  cursor: pointer;
}

.asset-sort.up path:first-child {
  stroke: #FB7B34;
}

.asset-sort.up path:nth-child(4) {
  stroke: #FB7B34;
}

.asset-sort.down path:nth-child(2) {
  stroke: #FB7B34;
}

.asset-sort.down path:nth-child(3) {
  stroke: #FB7B34;
}

.asset-class-data {
  border-top: none;
  border-bottom: 1px solid #777777;
  margin-top: 0;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
}

.asset-more-btn {
  padding-top: 5px;
}

.ant-layout-header.headerStyle {
  position: fixed;
  top: 0;
  right: 0;
  width: calc(100% - 280px);
  text-align: center;
  padding-inline: 24px;
  line-height: 64px;
  border-bottom: none;
  z-index: 1000;
  background-color: var(--bg-main-color);
}

.fbasis-22 {
  flex-basis: 22%;
}

.fbasis-18 {
  flex-basis: 18%;
}

.portfolio-table {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  .ant-table-thead>tr>th {
    background: #F7F7F8;
    font-family: Aeonik Quant;
    font-size: 16px;
    font-weight: 500;
    line-height: 18.56px;

    color: #525252;
    border-right: none !important;
  }

  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td {
    border-right: none !important;
  }

  .ant-pagination-item-active {
    border-color: #0F0F0F;

    a {
      color: #FB7B34;
    }
  }
}

.holdings-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 27.84px;
  text-align: left;
  color: #0F0F0F;
}

.holdings-table-row {
  font-size: 14px;
  background: #FFFFFF;

  &.is-odd {
    background-color: #F9FAFB !important;
  }

  &:hover {
    &>td {
      background-color: unset !important;
    }
  }
}

.holdings-instrument {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.56px;
  color: #0F0F0F;
}

.holdings-data {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.56px;
  letter-spacing: 0.04em;
  color: #525252;
}

.holdings-currency {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.24px;
  color: #525252;
}

.holdings-assetclass-cell-asset {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.56px;
  color: #0F0F0F;
  margin: 0 0 5px 0;
}

.holdings-assetclass-cell-category {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.24px;
  color: #808080;
  margin: 0;
}

.portfolio-table {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td {
    border-right: none !important;
  }

  .ant-pagination-item-active {
    border-color: #305572;

    a {
      color: #305572;
    }
  }
}

.holdings-title {
  font-size: 24px;
  font-weight: 700;
  line-height: 27.84px;
  text-align: left;
  color: #0F0F0F;
}

.holdings-table-row {
  font-size: 14px;
  background: #FFFFFF;

  &.is-odd {
    background-color: #F9FAFB !important;
  }

  &:hover {
    &>td {
      background-color: unset !important;
    }
  }
}

td.exclude-fee.holdings-fee>p {
  color: var(--bg-primary-color) !important;
}

.holdings-instrument {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.56px;
  color: #0F0F0F;
}

.holdings-currency {
  font-size: 14px;
  font-weight: 400;
  line-height: 16.24px;
  color: #525252;
}

.holdings-assetclass-cell-asset {
  font-size: 16px;
  font-weight: 500;
  line-height: 18.56px;
  color: #0F0F0F;
  margin: 0 0 5px 0;
}

.holdings-assetclass-cell-category {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.24px;
  color: #808080;
  margin: 0;
}

#horizontal_login.new-client-form,
#create-portfolio,
#instrument-form,
#reset,
#sign-up-form,
#forgot-password-form,
#change-pass,
#client-profile,
#finplan-item,
#onboarding-form,
#new-model-form,
#model-constraints-form,
#onboarding-form-first {
  font-size: 16px;
  font-weight: 400;
  line-height: 18.56px;

  & .custom-required {

    & .ant-form-item-label {

      &::before {
        display: none !important;
      }

      &>label::after {
        content: '*';
        color: var(--fc-label-color);
        visibility: visible;
      }
    }

  }

  & .ant-form-item-label {
    padding: 0 0 4px 24px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;

    &>label {
      color: var(--fc-label-color);
    }
  }

  & .ant-form-item-explain-error {
    padding-left: 24px;
  }

  & input {
    font-family: 'Aeonik Quant';
    font-size: 16px;
    font-weight: 400;
    line-height: 18.56px;
  }

}

.new-client-form-wrapper {
  padding: 0 24px;
}

.new-client-wrapper {
  padding: 28px 4px;
}

input.custom-input {
  width: 360px;
  height: 48px;
  border-radius: 24px;
  padding: 0 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.56px;
  color: #0F0F0F;
  background-color: var(--bg-primary-color);

  & .ant-input-number-input-wrap {
    margin-top: 10px;

    &>input {
      padding-left: 0;
    }
  }
}

.skeleton-container-flex {
  flex: 1;
}

.noportfolio {

  &-wrapper {
    min-height: calc(100vh - 240px);
    padding: 0 24px;
  }

  &-container {
    width: 100%;
    box-sizing: border-box;
    padding: 48px 24px;
    background-color: var(--bg-dropdown-color);
    border: 1px solid var(--border-hover-color);
  }

  &-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 100%;
    text-align: left;
    color: var(--fc-contrast);
    margin: 0;
  }

  &-text {
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    text-align: left;
    color: var(--fc-contrast);
    margin: 24px 0;
  }

  &-create-btn {
    font-family: 'Aeonik Quant';
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    padding: 10px 32px;
  }
}

.upload-block {
  padding: 26.5px 24px;
}

.upload-block-text {
  font-family: 'Aeonik Quant';
  font-size: 16px;
  font-weight: 500;
  line-height: 18.56px;

}

.upload-action-wrapper {
  flex-wrap: nowrap;
  gap: 24px;
}

.portfolio-creat-download {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #0F0F0F;
  border-radius: 24px;
  padding: 10px 24px;
  font-family: 'Aeonik Quant';
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;

  &-input {
    display: none;
  }
}

#create-portfolio {
  & .ant-checkbox-inner {
    width: 24px;
    height: 24px;
    border: 1px solid #000000;
    border-radius: 36.5px;
  }

  & .ant-checkbox-checked {
    &>.ant-checkbox-inner {
      background-color: #FB7B34;

      &::after {
        content: '';
        background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTAiIHZpZXdCb3g9IjAgMCAxNCAxMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjMzMzIgMUw0Ljk5OTg0IDguMzMzMzNMMS42NjY1IDUiIHN0cm9rZT0iIzFBMUExQSIgc3Ryb2tlLXdpZHRoPSIyIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cgo=") no-repeat center center;
        display: block;
        width: 100%;
        height: 100%;
        background-size: initial;
        transform: none;
        top: 0;
        inset-inline-start: 0;
        border: none;
      }
    }
  }
}

.portfolio_code-helper {
  margin: 0;
  padding: 0 0 0 24px;
  font-family: 'Aeonik Quant';
  font-size: 14px;
  font-weight: 400;
  line-height: 16.24px;
  text-align: left;
  color: #4D4D4D;

}

.portfolio-form-row {
  display: flex;
  justify-content: flex-start;
  align-items: start;
  gap: 16px;
}

.pie-charp-allocation-wrap {
  &>div>div {
    margin: 0 auto;
  }
}

.ant-table-wrapper .ant-table-column-title {
  position: relative;
  z-index: 1;
  flex: none;
}

.ant-table-wrapper .ant-table-column-sorters {
  justify-content: flex-start;
}

.holdings-data>.ant-table-column-sorters {
  justify-content: center;
}

.holdings-data.unitsNumber>.ant-table-column-sorters {
  justify-content: flex-end;
}

.holdings-data.latest-value>.ant-table-column-sorters {
  justify-content: flex-end;
}

.holdings-data.value>.ant-table-column-sorters {
  justify-content: flex-end;
}

.holdings-currency>.ant-table-column-sorters {
  justify-content: center;
}

.holdings-fee>.ant-table-column-sorters {
  justify-content: center;

}

.sortArroy {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 12px;
  margin-left: 4px;
  cursor: pointer;

  & svg {
    color: #00000091;
  }

  &>svg:first-child {
    margin-bottom: -3px;
  }
}

.portfolio-card-text {
  width: 120%;
  margin-left: -20px;
}

.portfolio-card-item {
  display: flex;
  align-items: center;
  flex-basis: 33.333%;
  gap: 4px;


  &-title {
    font-size: 12px;
    font-weight: 400;
    color: #4D4D4D;
    margin-bottom: 4px;
    white-space: nowrap;
    margin: 0;

    &.selected {
      color: #FEFEFE;
    }
  }

  &-value {
    display: inline-block;
    font-size: 10px;
    font-weight: 500;
    border-radius: 8px;
    white-space: nowrap;
    padding: 2px 2px;

    &.green {
      background: #139E191F;
      color: #139E19;
    }

    &.red {
      background: #DC180C1F;
      color: #DC180C;

    }
  }
}

.component-wrapper {
  width: 100%;
  min-height: 100vh;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.porfolio-info-actions-wrapper {
  margin-top: 78px;
  padding-top: 10px;
  display: flex;
  flex-wrap: nowrap;
  align-items: start;
  min-width: calc(100vh - 78px);

  &>.porfolio-info-wrapper {
    flex-basis: 27%;
    min-width: 450px;
  }

  &>.porfolio-actions-wrapper {
    flex-basis: 73%;
    display: flex;
    flex-direction: column;
    align-self: stretch;
    justify-content: space-between;
  }
}