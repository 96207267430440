.account-details-wrapper {
  margin-top: 80px;
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  align-items: start;
  min-height: calc(100vh - 80px);
  background-color: var(--root-theme-bcg);
  overflow-y: auto;
}

.account-info-wrapper {
  width: 450px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

}

.account-info-header {
  height: 80px;
  align-items: center;
  padding-inline: 24px;
}

.account-card {
  box-sizing: border-box;
  width: 418px;
  height: 180px;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 16px;
  border: 1px solid;

  padding: 24px 16px;
  background:
    center / cover no-repeat url('./img/error-bcg.svg'),
    linear-gradient(138.43deg, #FF9FEE 4.7%, #6467C3 52.57%);
}

.account-owner-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 4px;

  width: 80px;
  height: 24px;
  padding-inline: 8px;
  border-radius: 24px;
  background-color: var(--border-color);

  &>span {
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.6px;
    text-transform: uppercase;
    color: var(--fc-contrast);
  }

  &-wrap {
    display: flex;
    align-items: center;
    gap: 8px;
  }


}

.account-owner-link {
  cursor: pointer;
}

.account-owner-name {
  margin: 0;
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: -0.64px;
  text-align: right;
}

.account-owner-personalId {
  text-align: right;
  margin: 0;
}

.owner-personalId {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0;
  color: var(--fc-label-color);
  margin-right: 12px;

  &-number {
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0;
    color: var(--dd, #0F0F0F);
  }

}

.account-contact-inform {
  margin: 0;
  margin-bottom: 8px;
  padding-inline: 24px;

  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0;
  color: var(--fc-label-color);
}

.account-contact-wrap {
  margin-top: 5px;
  padding: 10px 18px;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--border-color);
}

.account-owner-header {
  margin: 0;
}

.account-contact-text {
  margin: 0;
  padding-inline: 24px;
  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0;
  color: var(--fc-contrast);
}

.account-creator-info-wrapper {
  display: flex;
  border-bottom: 1px solid var(--border-color);

  &>.account-creator-info-left {
    flex-basis: 50%;
    border-right: 1px solid var(--border-color);
    padding: 10px 24px 10px 42px;
  }

  & .details-create {
    font-weight: 400;
    font-size: 14px;
    line-height: 16.24px;
    letter-spacing: 0;
    color: var(--fc-label-color);
    margin: 0;
  }

  & .details-date {
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 10%;
    color: var(--fc-contrast);
    margin: 20px 0;
  }

  & .details-createdBy {
    font-weight: 500;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 10%;
    color: var(--fc-label-color);
    margin: 0;
  }

  &>.account-creator-info-right {
    flex-basis: 50%;
    padding: 10px 24px;
  }
}

.account-spaces {
  width: 100%;
  justify-content: space-between;

  &>.ant-space-item {
    width: 50%;
    padding-left: 42px;
    margin: 0 !important;

    &+div.ant-space-item {
      padding-left: 24px;
    }
  }
}

.account-divider {
  padding-inline: 24px;
  margin-top: 2px;
  margin-bottom: 20px;

  &.ant-divider {
    border-color: var(--border-color) !important;
  }
}

.account-inf-form {
  padding-inline: 24px;
}

.account-btn-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}