body.dark-theme {
  --container-border-radius: 13px;
}

body.light-theme {
  --container-border-radius: 10px;
}

.client.footer-holdings-wrapper {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;

  &>.footer-holdings-data {
    height: 72px;
    padding: 24px;
    flex-basis: 25%;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    border-right: 1px solid var(--stroke-stoke-button-default, #2B2726);

    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: right;
    color: var(--fc-contrast);

    & .footer-holdings-title {
      text-transform: uppercase;
    }

    &.holdings-total {
      background: var(--time-time-700, #1B1918);

    }


    & div {
      margin: 0;
    }

    & .holdings-data-text {
      margin-left: 10px;
    }
  }
}

.portfolio-side-wrap.advisot-flow .holdings-total {
  background-color: var(--bg-main-color) !important;
}