.custom-steps-wrapper {
  margin-top: 80px;
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-inline: 24px;
  border-radius: 24px;
  border: 1px solid var(--border-color);
}

.custom-steps-item-wrap {
  min-width: 270px;
  // width: 23%;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 24px;
  background-color: var(--step-btn-color);
  padding: 16px 24px;
  border: none;

  font-weight: 700;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.24px;
  text-transform: uppercase;

  opacity: var(--opacity-50, 1);
  color: var(--fc-contrast);

  &.current {
    opacity: 1;
    color: var(--fc-btn-color);
    background-color: var(--fc-secondary);
  }

}

.custom-steps-item-dot {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #FB7B34;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.24px;

  color: #0F0F0F;

}

.custom-steps-didider {
  display: inline-block;
  width: 16px;
  border: 1px solid #000000;
}