body.dark-theme {
    --search-input: #393332;
    --account-title: #8F8A89;
    --account-list-item-color: #7E716D;
    --account-list-item-color-hover: #FB7B34;
    --account-btn-border-color: #423A38;
    --account-btn-text-color: #FFFFFF;
}

body.light-theme {
    --search-input: transparent;
    --account-title: #0F0F0F;
    --account-list-item-color: #000000e0;
    --account-btn-border-color: #0F0F0F;
    --account-btn-text-color: #0F0F0F;
}

#advisers {
    background-color: var(--root-theme-bcg);
}

.sider-style {
    text-align: center;
    color: #fff;
    border-right: 1px solid #E1E1E9;

}

.logotype {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    z-index: 12;

    img {
        height: auto;
        width: 146px;
    }
}

.client-wrapper {
    display: flex;
    flex-direction: column;
    padding-bottom: 16px;
    min-height: calc(100vh - 100px);
    max-height: calc(100vh - 100px);

    & .logout-link {
        width: 100%;
        color: #DC180C;
        padding: 12px 56px;
        font-family: Aeonik Quant;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
        cursor: pointer;
    }
}

.account-button-wrapper {
    height: 300px;
    max-height: 48vh;
    overflow-y: auto;
    background-color: transparent;
    transition: max-height 0.5s ease-in-out,
        padding 0.5s ease-in-out,
        height 0.5s ease-in-out;

    &.hidden {
        height: 0;
        max-height: 0;
        padding-top: 0;
        overflow: hidden;
        transition: max-height 1s, height 1s ease-in-out;
        ;
    }
}




.client-box {

    &-title {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 10px;
        cursor: pointer;

        &.user-box {
            background: #F6F7F9;
            border-radius: 8px;

            &.selected-user {
                color: #0958d9;
                border-color: #0958d9;
                background: #ffffff;
            }
        }
    }

    &-text {
        font-size: 13px;
        font-weight: 500;
        line-height: 17.76px;
        letter-spacing: 0.02em;
        text-align: left;
        width: fit-content;
        margin: 0;
        padding: 0;
        color: #3C4043;

        &-wraper {
            box-sizing: border-box;
            width: 131.42px;
            text-align: left;
            padding-left: 10px;

            &>span.ant-typography {
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
            }

        }
    }
}

.client-box-title {
    width: 226px;
    margin-top: 16px;
    border: 1px solid #000000;
    border-radius: 24px;
    background-color: #FCFCFC;
    padding: 12px 16px;

    &.uncollapsed {
        border: none;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
    }

    &:not(.uncollapsed) {
        display: flex;
        align-items: center;
    }

    &.desabled {
        cursor: no-drop;
    }
}

.user-box {
    width: 226px;
    min-height: 64px;
    margin-top: 16px;
    border: 1px solid #000000;
    border-radius: 24px;
    background-color: #FCFCFC;
    padding: 12px 16px;
    transition: height 1s;

    &.uncollapsed {
        border: none;
    }

    &:not(.uncollapsed) {
        display: flex;
        align-items: center;
    }

    &-title {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        gap: 10px;
        cursor: pointer;
    }
}

.ant-btn.client-list-item {
    display: block;
    width: 100%;
    text-align: left;
    height: 44px;
    padding-left: 56px;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    &.selected-user {
        background: #ffffff;

        &>span {
            color: #0958d9;

        }
    }

}

.ant-btn.client-menu-btn {
    display: block;
    width: 100%;
    height: 44px;
    padding-left: 56px;
    text-align: left;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #0F0F0F;

    &.selected {
        // border-radius: 24px;
        // background: #FB7B34;
    }

}

li.ant-menu-submenu>.ant-menu.ant-menu-sub.ant-menu-inline {
    & .ant-menu-item {
        width: calc(100% - 16px);
        height: 44px;
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        color: #0F0F0F;
        margin: 0;

        &>.ant-menu-title-content {
            font-size: 14px;

        }

        &.ant-menu-item-selected {
            border-radius: 24px;
            background: #FB7B34;

            &>.ant-menu-title-content {
                font-size: 16px;

            }
        }
    }

}

div.ant-menu-submenu-title {
    &+ul.ant-menu.ant-menu-sub.ant-menu-inline {
        margin-top: 10px;
    }
}

.button-title-wrap {
    display: flex;
    flex-wrap: wrap;

    &>p {
        margin: 0;
        width: 100%;
    }
}

#advisers {
    & .client-nav-wrapper {
        padding: 0;
        max-height: 62vh;
        overflow-y: auto;
        border-top: 1px solid #423A38;

        &.hidden {
            max-height: 0;
            min-height: 0;
            padding-top: 0;
            overflow: hidden;
        }

        &>.ant-menu.ant-menu-root.ant-menu-inline.ant-menu-light {
            padding: 0;
            background-color: transparent;
            border-inline-end: none !important;

            & .ant-menu-submenu-title {
                padding-left: 14px;
                // background-color: #F6F7F9;
            }

            & .ant-menu.ant-menu-sub.ant-menu-inline {
                background: none !important;

                &>.ant-menu-item.ant-menu-item-only-child {
                    height: 48px;
                }
            }

            & span.ant-menu-title-content {
                text-align: left;
                font-size: 16px;
                font-weight: 500;
                line-height: 20px;
                color: #0F0F0F;
            }
        }

        .ant-menu-submenu-title {
            margin: 0 !important;
        }

    }

    & .account-title {
        padding-left: 32px;
        font-weight: 500;
        font-size: 12px;
        line-height: 14px;
        color: var(--account-title);
        margin: 0;
    }

    & .account-title-btn {
        padding: 8px 24px 16px 32px;
        gap: 10px
    }

    & .account-select-wrap {
        padding-bottom: 0;
        padding-left: 0;
        padding-right: 0;
        background-color: var(--light-bcg);

        &.collapsed>.account-title-btn {
            padding: 8px 0 16px 17.5px;
        }

        &.collapsed .client-menu-btn {
            padding: 0;
            padding-left: 26px;
        }
    }

    & .account-select-btn.collapsed {
        justify-content: flex-start;
        gap: 0px;
    }

    & .account-list-item {
        width: 100%;
        height: 44px;
        padding: 12px 24px;
        justify-content: flex-start;

        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        &>span {
            color: var(--account-list-item-color);
        }

        &.selected-account {
            &>span {
                color: var(--account-list-item-color-hover, #FB7B34);
            }

        }

    }

    & .menu-icon-wrap {
        width: 20px;
        height: 24px;

    }

    & .client-menu-btn {
        justify-content: flex-start;

        &:not(.selected) {
            gap: 0;
        }

        & .menu-icon-wrap>svg {
            opacity: 0;
        }

        &:hover .menu-icon-wrap>svg {
            opacity: 1;
        }

        & span {
            display: block;
            white-space: normal;
            overflow-wrap: break-word;
            word-wrap: break-word;
        }

    }
}

.account-select-search {
    background-color: var(--search-input) !important;
    margin-top: 8px;
    margin-bottom: 8px;
}