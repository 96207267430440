.custom-inputnumber {
    width: 100%;
    max-width: 360px;
    height: 48px;
    border-radius: 24px;

    &.ant-input-number {
        background-color: var(--bg-main-color);
        border: 1px solid var(--border-color);

        &:hover {
            border: 1px solid var(--border-color-hover);
            background-color: var(--bg-main-color);
        }

    }

    &.ant-input-number-outlined.ant-input-number-status-error,
    &.ant-input-number-outlined.ant-input-number-disabled,
    &.ant-input-number-outlined.ant-input-number-status-error:focus-within {
        background-color: var(--bg-main-color);

        &:hover {
            background-color: var(--bg-main-color);
        }
    }

    & .ant-input-number-input-wrap {
        padding-left: 24px;
        margin-top: 10px;

        &>input {
            padding-left: 0;
            font-size: 16px;
            font-weight: 400;
            line-height: 18.56px;
            color: var(--fc-contrast);

        }
    }

    & .ant-input-number {
        border-radius: 24px;
        padding-left: 24px;
    }

    & .ant-input-number-handler-wrap {
        margin-right: 16px;
        border-right: 1px solid rgb(217, 217, 217);
    }

    &.persent {

        & .ant-input-number-handler-wrap {
            margin-right: 56px;
        }
    }

    .ant-input-number-handler-wrap {
        background-color: var(--input-background-color);
        border-color: var(--border-hover-color);

        &>span {
            border-inline-start: 1px solid var(--border-hover-color);
        }

        &>span.ant-input-number-handler-down {
            border-block-start: 1px solid var(--border-hover-color);
        }
    }

    .ant-form-item-explain-error {
        white-space: normal;
        overflow-wrap: break-word;
        word-wrap: break-word;
    }


}

.custom-inputnumber-suffix {
    position: absolute;
    top: 1px;
    right: 16px;
    height: 32px;
    display: flex;
    align-items: center;

    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    color: var(--fc-input-color);

}