.controll-wrapper {
    padding: 0 2px;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #0F0F0F;
    background-color: #0F0F0F;
    min-width: 100px;
    height: 22px;
    border-radius: 48px;
}

.filter-control-wrapper {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
}

.controll-decrease,
.controll-increase {
    padding: 0;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.control-min-value,
.control-max-value {
    font-family: Aeonik Quant;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.56px;
    color: #FB7B34;
    display: inline-block;
    width: 54px;
    background-color: #0F0F0F;
    border: none;
    text-align: center;

    &:active,
    &:focus,
    &:focus-visible {
        outline: none;
        box-shadow: none;
    }

}

#filter-config {

    & input[type="number"]::-webkit-inner-spin-button,
    & input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    & input[type="number"] {
        -moz-appearance: textfield;
    }
}

.controll-delimiter {
    font-family: Aeonik Quant;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: #0F0F0F;
}

.filter-control-title {
    display: flex;
    align-items: center;
    font-family: Aeonik Quant;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    text-align: left;
    color: #0F0F0F;
}

.fillter-price-title {
    margin: 4px 0 20px 0;
    font-family: Aeonik Quant;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    color: #0F0F0F;
}

.filter-check-item {
    margin-bottom: 0;
}

.filter-apply {
    min-width: 124px;
    color: #FCFCFC;
    background-color: #0F0F0F !important;
}

.filter-reset {
    border: 1px solid #0F0F0F
}