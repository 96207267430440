label.opt-portfolio-form-subheading {
    display: inline-block;
    font-family: Aeonik Quant;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    color: #0F0F0F;
    margin-bottom: 16px;

    &.fee {
        padding-left: 24px;
        margin-bottom: 4px;
    }
}

#opt-fees-portfolio .opt-portfolio-form-subheading {
    display: inline-block;
    margin-bottom: 12px;
}

#opt-fees-portfolio .m-bottom-24 {
    display: inline-block;
    margin-top: 24px;
}

.m-bottom-0 {
    margin-bottom: 0;
}

.opt-portfolio-text>span {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.20000000298023224px;
    text-align: left;
}

.fee-input-wrapper {
    margin-top: 24px;
}

.fees-input {
    display: flex;
    align-items: center;
    width: 440px;
    height: 40px;
    border-radius: 5px;
    border: 1px solid #DCDDDD;
    background: #FFF;
    color: #626363;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.2px;
}

.ant-steps-label-vertical .ant-steps-item-content {
    width: 135px !important;
}

.portfolio-optimization-form .opt-portfolio-form-container {
    padding: 0;
}

.inner-wrapper {
    padding: 25px 24px;

    &.settings>form {
        padding: 0;
        margin: 0;
        width: 652px;
        box-sizing: border-box;
        margin-left: auto;
        margin-right: auto;
    }
}

.orang-dots {
    color: Orange;
    padding: 0;
    margin: 0 5.5px;
    font-family: Aeonik Quant;
    font-size: 24px;
    font-weight: 700;
    line-height: 27.84px;

}

.settings-point-text {
    font-size: 20px;
    font-weight: 500;
    line-height: 23.2px;
    text-align: left;
    color: #0F0F0F;
}

.settings-withdrawn-input-wrapper {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;
    gap: 26.5px;

    &>p {
        font-size: 16px;
        font-weight: 400;
        line-height: 18.56px;
        text-align: left;

    }
}

.ant-col.currency-sek-wrapper {
    width: 296px;
    display: flex;
    justify-content: space-between;
    gap: 16px;

    & .currency-sek-text {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        letter-spacing: 0;
        text-align: right;
        color: var(--fc-contrast);
        margin: 0;
    }
}

.btn-optim {
    box-sizing: border-box;
    height: 32px;
    padding: 8px 12px;
    border-radius: 24px;
    border: 1px solid #6467C3;

    &.optim-continue-btn {
        background-color: #6467C3;
    }

    &>span {
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        color: var(--fc-contrast);
    }

    &:hover {
        background-color: transparent !important;
        border: 1px solid #6467C3 !important;
    }
}

.settings-row {
    margin-top: 12px;

    & p {
        font-weight: 500;
        font-size: 14px;
        line-height: 110.00000000000001%;
        letter-spacing: -2%;
        color: var(--fc-contrast);
        margin: 0;
        margin-bottom: 20px;
    }

    & .opt-settings-number-item {
        & .custom-input {
            height: 32px;
            background-color: var(--bg-select-disable-color);

            & .ant-input-number-input-wrap {
                margin: 0;
                height: 32px;
                display: flex;
                align-items: center;
            }

            & input {

                font-weight: 500;
                font-size: 16px;
                line-height: 16px;
                color: var(--fc-input-color);
                text-align: right;
                padding: 0;
                padding-right: 50px;
            }
        }
    }

    & .opt-settings-radio-item {
        & .ant-space-item {
            width: 203px;
            height: 32px;

            & .ant-radio-button-wrapper {
                width: 100%;
                display: flex;
                align-items: center;
                border-radius: 24px;
                border: 1px solid var(--border-color);
                background-color: transparent;

                &>span:not(.ant-radio-button) {
                    width: 100%;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                }

                &.ant-radio-button-wrapper-checked {
                    border: 1px solid var(--fc-secondary);
                    background-color: var(--fc-secondary);

                    & span {
                        color: var(--fc-btn-color);
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        &>svg.radio-check {}
                    }
                }

                & span {
                    text-align: left;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 16px;
                    color: var(--border-dropdown-color);

                }

                &:not(.ant-radio-button-wrapper-checked) {
                    & svg.radio-check {
                        visibility: hidden;
                    }

                }
            }
        }
    }
}

.opt-settings-check-item {
    & .round-checkbox {

        & span.ant-checkbox {
            border: 1px solid var(--border-hover-color);
            border-radius: 50%;

            &+span {
                font-weight: 400;
                font-size: 14px;
                line-height: 100%;
                color: var(--fc-contrast);
            }

            &.ant-checkbox-checked>.ant-checkbox-inner {
                border-radius: 50%;
            }
        }
    }
}