body.dark-theme {
  --header-title-color: #FFFFFF;
  --warning-bcg-color: #2B2726;
  --title-color: #8B8A8A;
  --bankid-btn-bcg: #F2EEED;
  --bankid-btn-border: #ffffff;
  --logo-url: url('./PersonalNumberRow/img/BankID_logo_white.png');
  --twoFactorText: #8B8A8A;
  --btn-hover: #1B1918;
  --label-color: #666666;
  --primary-btn-bcg-color: #F2EEED;
  --primary-btn-bcg-hover-color: #FBF9F9;
  --primary-btn-color: #1B1918;
  --primary-btn-border-color: none;
  --primary-btn-border-hover-color: none;
}

body.light-theme {
  --header-title-color: #232323;
  --warning-bcg-color: #FFFFFF;
  --title-color: #0F0F0F;
  --bankid-btn-bcg: #ffffff;
  --bankid-btn-border: #0F0F0F;
  --logo-url: url('./PersonalNumberRow/img/BankID_logo_black.png');
  --twoFactorText: #0F0F0F;
  --btn-hover: #FB7B34;
  --label-color: #0F0F0F;
  --primary-btn-bcg-color: #FCFCFC;
  // --primary-btn-bcg-hover-color: #F2EEED;
  --primary-btn-color: #0F0F0F;
  --primary-btn-border-color: #0F0F0F;
  --primary-btn-border-hover-color: #FB7B34;
}

.profile-container {
  margin: 0 auto;
  position: relative;
  margin-top: 24px;
  display: flex;
  flex-direction: column !important;
  max-width: 960px;
  width: 100% !important;
}

.ant-divider-horizontal.profile-divider {
  margin: 24px 0;
}

.profile-subtitle {
  text-align: center;
}

.profile-switcher-wrapper {
  display: flex;
  justify-content: space-between;
}

.ant-alert.profile-alert {
  position: fixed;
  top: 120px;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 800px;
  width: 100%;
}

.setting-header-title {
  margin: 0;
  font-family: Aeonik Quant;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.2px;
  text-align: left;
  color: #0F0F0F;
}

.client-delete-btn {
  & svg {
    width: 16px;
    height: 16px;
  }
}

.btn-wrapper {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 24px;
}

button.portfolio-export-btn.profile-btn,
button.btn-edit-save.profile-btn {
  padding: 0 12px;
  min-width: 144px;
}

button.btn-edit-save.optimise-btn {
  margin-bottom: 20px;
  margin-left: 115px;
}

.ant-form-item-explain.ant-form-item-explain-connected {
  max-width: 360px;
}

.client.profile-wrap {
  width: 100%;
  min-height: 100vh;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--content-row-bcg);
}

.client.settings-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.02em;
  text-align: left;
  color: var(--header-title-color);
  margin: 0;
}

.is2fa-warning-wrapper {
  display: flex;
  justify-content: center;
  padding: 0 24px;
}

.client.number-is2fa-warning {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  width: 100%;
  padding: 2.5px;
  border-radius: 24px;
  background-color: var(--warning-bcg-color);
  color: var(--fc-contrast, #FFF);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.client.setting-header-title {
  color: var(--title-color);
}

.client.suport-header-title {
  color: var(--title-color);
}

div.twofactor-input.client input:-internal-autofill-selected,
div.twofactor-input.client input:-webkit-autofill,
div.twofactor-input.client input:-internal-autofill-previewed,
input.client.custom-input:-internal-autofill-selected,
input.client.custom-input:-webkit-autofill,
input.client.custom-input:-internal-autofill-previewed {
  background-color: var(--bg-secondary-color) !important;
  -webkit-text-fill-color: var(--fc-secondary) !important;
  transition: background-color 5000s ease-in-out 0s;
}

input.client.custom-input {
  width: 360px;
  height: 48px;
  border-radius: 24px;
  padding: 0 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.56px;
  color: var(--fc-secondary);
  background-color: var(--bg-secondary-color);
  border: 1px solid var(--border-color);

  &:focus {
    border: 1px solid var(--login-btn-border-hover);
  }

  &.ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
    background-color: var(--bg-secondary-color);
  }

  & .ant-input-number-input-wrap {
    margin-top: 10px;

    &>input {
      padding-left: 0;
    }
  }

  & label {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    color: var(--login-label-fc);

  }

  & input.login-input.ant-input-outlined,
  & span.login-input.ant-input-outlined {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.56px;
    width: 360px;
    height: 48px;
    border-radius: 24px;
    padding: 8px 24px;
    background-color: var(--bg-secondary-color);
    color: var(--fc-secondary);

    &::placeholder {
      color: var(--login-placeholder-fc);
    }
  }

  & span.login-input.ant-input-outlined>input {
    color: var(--fc-secondary);

    &::placeholder {
      color: var(--login-placeholder-fc);
    }
  }

  & .ant-col.ant-form-item-label {
    padding: 0 0 4px 24px;
  }
}

.client.profile-bankid-btn {
  width: 360px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 24px;
  border: 1px solid var(--bankid-btn-border);
  transition: box-shadow 0.3s ease;
  background-color: var(--bankid-btn-bcg) !important;
  color: #0F0F0F !important;
  cursor: pointer;

  font-size: 16px;
  font-weight: 500;
  line-height: 20px;


  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  }
}

.ant-switch.client.twoAuth-swich-button {
  width: 40px;
  height: 22px;
  border: 1px solid var(--login-switch-border-color, #0F0F0F);
  background: var(--warning-bcg-color);

  &+label {
    margin-left: 8px;
    cursor: pointer;
    color: var(--title-color);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  &:not(.ant-switch-checked) {
    & .ant-switch-handle::before {
      border: 1px solid var(--login-switch-handle-border);
      background-color: var(--warning-bcg-color);
    }

    ;
  }

  &>.ant-switch-handle::before {
    border: 1px solid var(--login-switch-handle-border);
    background-color: var(--login-switch-handle-border);
  }

}

.modal-bankId-wrap {
  & .ant-modal .ant-modal-content {
    background: var(--bg-secondary-color);

  }

  & .bankId-logo,
  & .ant-modal-header {
    background-color: var(--bg-secondary-color);
  }

  & .bankId-logo {
    width: 100px;
    height: 100px;
    background-image: var(--logo-url);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 auto;

    &-wrapper {
      width: 100%;
      text-align: center;
    }
  }

  & .modal-bankId-code-wrapper,
  & .modal-twofactor-wrapper {
    width: 215px;
    height: 215px;
    margin: 0 auto;
    text-align: center;
    padding: 7.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;

    &.white {
      background-color: #ffffff;
    }
  }

  & .mail-change-link {
    color: var(--header-title-color);

    & svg>g>path {
      fill: var(--login-forgot-arrow, #0F0F0F)
    }
  }

  & .modal-twofactor-header,
  & .modal-twofactor-title {
    color: var(--header-title-color);
  }

  & .modal-twofactor-title {
    color: var(--header-title-color);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  & .modal-twofactor-header {
    color: var(--header-title-color);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
  }

  & .modal-twofactor-list>li::marker {
    color: var(--header-title-color);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  & .modal-twofactor-text {
    color: var(--twoFactorText);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
}

div.twofactor-input.client.ant-input-number-input-wrap {
  margin: 0;
  padding: 0;
}

div.twofactor-input.client,
div.twofactor-input.client input {
  width: 100%;
  height: 48px;
  border-radius: 24px;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.56px;
  color: var(--fc-secondary);
  background-color: var(--bg-secondary-color);
  border: 1px solid var(--border-color);

  &:focus {
    border: 1px solid var(--login-btn-border-hover);
  }

  & .ant-input-number-input-wrap {
    padding-left: 0;
    margin-top: 0;

    &>input {
      padding-left: 20px;
    }
  }

  & label {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    color: var(--login-label-fc);

  }

  & input.login-input.ant-input-outlined,
  & span.login-input.ant-input-outlined {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.56px;
    width: 360px;
    height: 48px;
    border-radius: 24px;
    padding: 8px 24px;
    background-color: var(--bg-secondary-color);
    color: var(--fc-secondary);

    &::placeholder {
      color: var(--login-placeholder-fc);
    }
  }

  & span.login-input.ant-input-outlined>input {
    color: var(--fc-secondary);

    &::placeholder {
      color: var(--login-placeholder-fc);
    }
  }

  & .ant-col.ant-form-item-label {
    padding: 0 0 4px 24px;
  }
}

div.twofactor-input.client {
  border: none;
}

.client.twofactor-input-btn {
  height: 32px;
}

.twofactor-button-wrapper>button.ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover {
  color: var(--btn-hover);

}

.custom-required.client {
  & label {
    color: var(--label-color);
  }
}

.client-outline-btn {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  color: var(--fc-contrast);

  border-radius: 24px;
  border: 1px solid var(--border-color);
  background-color: transparent;

  &.ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover {
    color: var(--fc-contrast);
    background-color: transparent;
    border: 1px solid var(--border-hover-color); // #423A38

  }

  & .ant-btn-icon>svg {
    margin-top: 3px;
  }
}

.client-primary-btn {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;

  border-radius: 24px;
  border-color: var(--primary-btn-border-color);
  background: var(--primary-btn-bcg-color);
  color: var(--primary-btn-color);
  padding: 0 20px;
  min-width: 76px;

  &.ant-btn-variant-outlined:not(:disabled):not(.ant-btn-disabled):hover {
    background: var(--primary-btn-bcg-hover-color);
    color: var(--primary-btn-color);
    border-color: var(--primary-btn-border-hover-color);
  }
}

.client-input-label {
  & .ant-form-item-label {
    & label {
      color: var(--label-color);
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}

.settings.profile-wrap {
  width: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: var(--bg-main-color);
}

.profile-delete-btn.delete-phone {
  position: absolute;
  top: 35px;
  right: 20px;
  cursor: pointer;
}

.settings .form-phone {
  width: 100%;

  & input.ant-input {
    border: 1px solid var(--border-dropdown-color);
  }
}

.settings .phone-number-wrapper .select-phonecode {
  background-color: transparent;
  border: 1px solid var(--border-dropdown-color);
}

.onboarding-custom-inputnumber-dropdown {
  width: 360px !important;
}

.setting-password-row .ant-input-outlined.ant-input-password.custom-password {
  background-color: var(--bg-main-color);
  border: 1px solid var(--border-dropdown-color);

  &:hover,
  &:focus {
    background-color: var(--bg-main-color) !important;
    border: 1px solid var(--border-dropdown-color);
  }
}

.settings-container {
  padding: 24px;
  border: 1px solid var(--border-dropdown-color);
  border-radius: 13px;
}

.personal-number-row {
  & .ant-input.ant-input-disabled {
    background-color: var(--border-color);
    border: 1px solid var(--border-dropdown-color);
  }
}

.settings-container.profile {
  & input.ant-input-outlined.custom-input {
    border: 1px solid var(--border-dropdown-color) !important;
  }
}