.opt-title-wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &>.opt-title-column {
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 78px;
        border: 1px solid var(--border-color);

        &>.opt-paper-title {
            padding-inline: 24px;
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.7px;
            text-transform: uppercase;
            color: var(--fc-secondary-light);
        }

        &>.holdings-value {
            padding-inline: 24px;
            font-weight: 500;
            font-size: 24px;
            line-height: 24px;
            letter-spacing: -0.48px;
            text-align: right;
            color: var(--fc-contrast);
        }
    }
}

.opt-total-wrap {
    height: 72px;
    border-right: 1px solid var(--border-color);
    background-color: var(--bg-dropdown-color);
    padding-inline: 24px;

    & .holdings-text {
        font-weight: 700;
        font-size: 12px;
        line-height: 14px;
        letter-spacing: 0.7px;
        text-transform: uppercase;
        color: var(--fc-secondary-light);
    }

    & .holdings-value-wrapper {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
    }

    & .holdings-value {
        font-weight: 500;
        font-size: 24px;
        line-height: 24px;
        letter-spacing: -0.48px;
        color: var(--fc-contrast);
    }

    & .holdings-value-fee {
        font-weight: 700;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 1.2px;
        text-transform: uppercase;
        color: var(--fc-prim-color);
    }
}

h2.opt-block-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.48px;
    color: var(--fc-contrast);
    padding-left: 24px;
}