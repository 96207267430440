code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
  font-family: 'Aeonik Quant';
  src:
    url('./assets/aeoniktrial-regular-webfont.woff2') format('woff2'),
    url('./assets/aeoniktrial-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Aeonik Quant';
  src:
    url('./assets/aeoniktrial-regularitalic-webfont.woff2') format('woff2'),
    url('./assets/aeoniktrial-regularitalic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Aeonik Quant';
  src:
    url('./assets/aeoniktrial-medium-webfont.woff2') format('woff2'),
    url('./assets/aeoniktrial-medium-webfont.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Aeonik Quant';
  src:
    url('./assets/aeoniktrial-mediumitalic-webfont.woff2') format('woff2'),
    url('./assets/aeoniktrial-mediumitalic-webfont.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Aeonik Quant';
  src:
    url('./assets/aeoniktrial-bold-webfont.woff2') format('woff2'),
    url('./assets/aeoniktrial-bold-webfont.woff') format('woff');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Aeonik Quant';
  src:
    url('./assets/aeoniktrial-bolditalic-webfont.woff2') format('woff2'),
    url('./assets/aeoniktrial-bolditalic-webfont.woff') format('woff');
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: 'Aeonik Quant';
  src:
    url('./assets/aeoniktrial-bold-webfont.woff2') format('woff2'),
    url('./assets/aeoniktrial-bold-webfont.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: 'Aeonik Quant';
  src:
    url('./assets/aeoniktrial-bolditalic-webfont.woff2') format('woff2'),
    url('./assets/aeoniktrial-bolditalic-webfont.woff') format('woff');
  font-weight: 700;
  font-style: italic;
}
@font-face {
  font-family: 'Aeonik Quant';
  src:
    url('./assets/aeoniktrial-black-webfont.woff2') format('woff2'),
    url('./assets/aeoniktrial-black-webfont.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: 'Aeonik Quant';
  src:
    url('./assets/aeoniktrial-blackitalic-webfont.woff2') format('woff2'),
    url('./assets/aeoniktrial-blackitalic-webfont.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}
@font-face {
  font-family: 'Aeonik Quant';
  src:
    url('./assets/aeoniktrial-air-webfont.woff2') format('woff2'),
    url('./assets/aeoniktrial-air-webfont.woff') format('woff');
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: 'Aeonik Quant';
  src:
    url('./assets/aeoniktrial-airitalic-webfont.woff2') format('woff2'),
    url('./assets/aeoniktrial-airitalic-webfont.woff') format('woff');
  font-weight: 100;
  font-style: italic;
}
body {
  margin: 0;
  background-color: var(--root-theme-bcg);
}
* {
  font-family: 'Aeonik Quant' !important;
}
