.out-container-title.list-title {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: 24px;
}

.maps-tabs-wrapper {
    flex-wrap: nowrap;
    margin: 0 0 48px;
    padding: 0;
    gap: 24px;
}

.map-name-wrapper {
    min-width: 1128px;
    display: flex;
    flex-wrap: nowrap;
    gap: 48px;
    padding: 0 24px;
}

.map-name-column {
    width: 50%;
}

.map-description-column {
    width: 50%;

}

.select-all-wrap {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 6px;
}

.custom-select-wrapper {
    padding: 0 24px 24px;

    & .ant-select-item.ant-select-item-option.ant-select-item-option-active {
        border-color: rgb(252, 182, 97);

    }

    & .ant-select-item.ant-select-item-option.ant-select-item-option-selected {
        border-color: #FB7B34;

        &>div.ant-select-item-option-content {}
    }
}

th.first-column-p24 {
    padding-left: 24px !important;
}

td.first-column-p24 {
    padding-left: 24px !important;
}

.recomend-maps-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.4px;
    padding: 24px;
    color: var(--fc-contrast);
    margin: 0;
}

.recomend-input-wrap {
    & .recomend-input {
        border-color: var(--border-dropdown-color) !important;

        &:hover {
            border-color: var(--border-dropdown-color-light) !important;
        }
    }
}

.recomend-textaria-wrap {
    & .onboarding-textarea {
        background: transparent !important;
        border-color: var(--border-dropdown-color);

        &:hover {
            border-color: var(--border-dropdown-color-light);
        }
    }
}

.client.portfolio-table .ant-select-selection-search>input.ant-select-selection-search-input {
    color: var(--fc-contrast);
}

.operation svg path {
    fill: var(--fc-prim-color);
}