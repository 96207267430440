.modal-content-paper {
    height: 375px;
    border-radius: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &>div {
        width: 100%;
    }
}

.modal-title {
    font-family: 'Aeonik Quant';
    font-size: 24px;
    font-weight: 700;
    line-height: 27.84px;
    margin-top: 52px;
    text-align: center;
}

.modal-text {
    font-family: 'Aeonik Quant';
    font-size: 16px;
    font-weight: 400;
    line-height: 18.56px;
    text-align: center;
    margin: 0;
}

.modal-button-wrapper {
    justify-content: center;
    gap: 16px;
    margin-top: 69px;
    margin-bottom: 72px;
}

.modal-action-btn {
    height: 32px;
    padding: 10px 32px;
    font-family: 'Aeonik Quant';
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 24px;
    border: 1px solid #0F0F0F;

    &.cancel-btn {
        color: #0F0F0F;
    }

    &.save-btn {
        color: #FCFCFC;
        background-color: #0F0F0F;

        &:hover {
            color: #FCFCFC !important;
            background-color: #0F0F0F !important;
            border: 1px solid #FB7B34 !important;

            &>span {
                color: #FCFCFC !important;
            }
        }
    }
}