.container-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 23.2px;
    text-align: left;
    padding: 31px 40px;
    margin: 0;
}

.model-table-name {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.56px;
    text-align: left;
    margin: 0;
    margin-bottom: 5px;
}

.model-table-subname {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    text-align: left;
    margin: 0;
    color: var(--ff, #999999);
}

.model-table-return {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.56px;
    letter-spacing: 0.04em;
    text-align: center;

    & .ant-table-column-title {
        font-size: 16px;
        font-weight: 500;
        line-height: 18.56px;
        color: #525252;
    }

    &>.ant-table-column-sorters {
        justify-content: left;
    }

}

th.exclude-holdings.exclude-name.model-name,
td.exclude-holdings.exclude-name.model-name {
    padding-left: 30px;
}

.titlerow-btn-wrapper {
    display: flex;
    align-items: center;
    padding-right: 24px;

    & .portfolio-creat-download {
        height: 40px;
    }
}

.upload-model-btn {
    background-color: #FCFCFC;
}

.custom-select.asset-select-models .ant-select-selection-item {
    display: flex;
    gap: 10px;
    align-items: center;
    color: var(--fc-secondary) !important;

    & .asset-option-status {
        border-color: var(--fc-label-color);
    }
}

.asset-option-status {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid #000000;
    margin-right: 10px;

    &.partially {
        background-color: #FFAB00;
    }

    &.full {
        background-color: #FB7B34;
        background-image: url('../../assets/check-icon.svg');
        background-position: center center;
        background-repeat: no-repeat;
    }
}

.model-series-headers {
    background-color: transparent;
    padding: 15px 0;
    border-bottom: 1px solid var(--border-color);
}

.model-series-headers-cell {
    flex-basis: 7.69%;
    text-align: center;
    color: var(--fc-header-color);
}

.model-series-row-cell {
    flex-basis: 7.69%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 10px;
    color: var(--fc-contrast);
}

.model-series-row {
    height: 80px;
    border-bottom: 1px solid var(--border-color);
}

input.custom-inputnumber.model-series {
    width: 100%;
}

.custom-inputnumber.model-series {
    border: 1px solid var(--border-dropdown-color) !important;
}

.out-container-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 27.84px;

    margin: 0;
    padding: 0;
    padding-left: 24px;
}

.model-modal-title {
    font-size: 24px;
    font-weight: 700;
    line-height: 27.84px;
    text-align: center;
    margin-bottom: 36px;

}

#new-model-form {
    width: 100%;
    padding: 24px;
}

.modal-action-btn.save-btn.model-modal-btn {
    width: 100%;
}

.model-modal-subtitle {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.56px;
    text-align: center;
    margin: 0;
    margin-bottom: 24px;

}

.clone-checkbox-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 8px 0 28px;
}

.modellist-wrapper {
    background-color: #F6F7F9;
    position: relative;
    min-height: calc(100vh - 160px);
    max-height: calc(100vh - 160px);
    overflow-y: auto;
}

.model-tabs-wrapper {
    height: 40px;
    flex-wrap: nowrap;
    padding-inline: 24px;
    margin-top: 80px;
    margin-bottom: 24px;
}

.model-tabs-column {
    flex-basis: 50%;
}

.model-tab-btn-wrapper {
    width: 100%;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 24px;

    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    text-transform: uppercase;
    opacity: var(--opacity-50, 1);
    color: var(--fc-contrast);

    border: 1px solid transparent;
    background-color: transparent;
    cursor: pointer;

    &.selected {
        opacity: 1;
        background-color: var(--fc-secondary);
        color: var(--fc-btn-color);

    }

    &>.model-tab-btn-round {
        width: 24px;
        height: 24px;
        border: 1px solid #000000;
        border-radius: 50%;
        background-color: #FCFCFC;
    }

}

.model-tab-btn-title {
    margin: 0;
}

.series-data-cell {
    font-size: 16px;
    font-weight: 500;
    line-height: 18.56px;
    letter-spacing: 0.04em;

}

#primitives-form {
    padding: 0;
}

.primitives-header {
    padding: 25.5px 30px;
    background-color: transparent;
}

.primitives-title-class,
.primitives-title-override,
.primitives-header-value,
.primitives-title-value {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 12px;
    font-weight: 700;
    line-height: 12px;
    color: var(--fc-header-color);
    text-transform: uppercase;

    &.contrast {
        color: var(--fc-contrast);
    }
}

.primitives-title-override.cell {
    text-align: left;
    margin-left: -24px;
}

.primitives-title-override.cell {
    text-align: left;
    margin-left: -24px;
}

.primitives-title-value {
    justify-content: center;

    &.cell {
        margin-left: -24px;
    }
}

.primitives-cell-name {
    font-weight: 500;
    font-size: 14px;
    line-height: 110.00000000000001%;
    letter-spacing: -0.28px;
    color: var(--fc-contrast);
    text-transform: none;
    margin: 0;
    margin-bottom: 5px;
}

.primitives-cell-category {
    font-weight: 500;
    font-size: 14px;
    line-height: 110.00000000000001%;
    letter-spacing: -0.28px;
    text-transform: none;

    color: #999999;
    margin: 0;
}

.error-del-modal {
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    text-align: left;
    color: red;

}

.primitives-row {
    width: 100%;
    margin: 0 20px;
    padding: 16px 10px;
    background-color: transparent;
    border-bottom: 1px solid var(--border-color);
}

.ant-select.ant-select-outlined.custom-select.label {
    max-width: 100%;
}

#model-constraints-form {
    padding: 0;
}

.ratio-constraints-wrapper {
    padding: 0 24px 48px;
}

.ratio-constraints-row {
    max-width: 812px;
    gap: 48px;
}

.custom-required.ratio-constraints-asset {
    margin-bottom: 0 !important;
}

.ratio-constraints-divider.ant-divider-horizontal {
    margin: 2px 0;
    min-width: 360px;
    width: 360px;
    color: #4D4D4D;
    border-color: #4D4D4D
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    /* Убирает стрелки в браузерах на основе WebKit */
    margin: 0;
    /* Убирает возможные дополнительные отступы */
}

input[type="number"] {
    -moz-appearance: textfield;
    /* Убирает стрелки в Firefox */
}

.controll-wrapper.controll-constraints {
    width: 100px;
}

.ratio-constraints-divider.ant-divider-horizontal.weight {
    min-width: 100px;
    width: 100px;
}

.controll-constraints-item {
    margin-bottom: 0;
}

.control-min-value {
    padding: 0;
    background-color: transparent;
}

.controll-constraints-item {
    & .ant-col.ant-form-item-label {
        padding: 0 !important;
        text-align: center !important;
    }

    & .ant-input-outlined {
        padding-top: 4px;
    }

    & .ant-input-outlined:hover,
    & .ant-input-outlined:focus {
        border: none;
        background-color: transparent;
    }

    & .ant-form-item-control-input {
        min-height: auto;
    }

    & .ant-form-item-control-input-content {
        height: 22px;
        display: flex;
        align-items: center;
    }

    & .controll-constraints.without-label .ant-form-item {
        margin-bottom: 0;
    }
}

.ratio-constraints-row {
    margin-bottom: 24px;

    & .ant-col.comparison-column>.ant-form-item {
        margin-bottom: 0;
    }

    & .ant-form-item.weight-data {
        width: 54px;
    }
}

.titlerow-btn-wrapper.download-series {
    display: flex;
    justify-content: flex-end;
    text-align: right;
    gap: 24px;
}

.model-table-return.status {
    text-transform: capitalize;
}

.modal-select-status {
    font-size: 18px;
    font-weight: 500;
    line-height: 18.56px;
    text-align: center;
    color: #262626;
    margin-bottom: 24px;
}

.exclude-holdings.model-actions {
    svg path {
        fill: var(--fc-prim-color);
    }
}

.model {
    & .model-card {
        box-sizing: border-box;
        width: 100%;
        height: 180px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        border-radius: 16px;
        border: 1px solid;

        padding: 24px 16px;
        background:
            center / cover no-repeat url('./img/error-bcg.svg'),
            linear-gradient(180deg, #FF9FEE 11.14%, #77D5EC 83.42%);
    }

    & .modal-result-short {
        font-weight: 500;
        font-size: 32px;
        line-height: 32px;
        letter-spacing: -0.64px;
        text-align: right;
        color: var(--fc-btn-color);
    }

    & .modal-result-footer {
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0;
        text-align: right;
        color: var(--fc-label-color);
    }

    & #onboarding-form .clone-checkbox-wrapper.model-clone {
        & label>span {
            font-weight: 400;
            font-size: 14px;
            line-height: 100%;
            letter-spacing: 0;
            color: var(--fc-contrast);
        }

    }

    & .onboarding-textarea-purpose textarea {
        background: var(--bg-main-color) !important;

        &.ant-input-outlined:hover,
        &.ant-input-outlined:focus {
            border-color: var(--border-dropdown-color);
        }
    }

    & .modal-action-btn.save-btn.asset-model {
        background-color: var(--fc-optim-color);
    }
}

button.complete-btn {
    color: var(--fc-contrast);
    background-color: var(--fc-optim-color);

    &:hover {
        border-color: var(--border-hover-color) !important;
        background-color: var(--fc-optim-color) !important;
    }

    &:disabled {
        opacity: 0.3;
        border-color: var(--border-hover-color) !important;
        background-color: var(--fc-optim-color) !important;
    }
}

.model-settings {
    width: 652px;
    margin: 0 auto;

    & .veryfication-card {
        box-sizing: border-box;
        width: 100%;
        height: 180px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        border-radius: 16px;
        border: 1px solid;

        padding: 24px 16px;
        background:
            center / cover no-repeat url('./img/error-bcg.svg'),
            linear-gradient(180deg, #FF9FEE 11.14%, #77D5EC 83.42%);


        & .modal-result-short {
            font-weight: 500;
            font-size: 32px;
            line-height: 32px;
            letter-spacing: -0.64px;
            text-align: right;
            color: var(--fc-btn-color);
        }

        & .modal-result-footer {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0;
            text-align: right;
            color: var(--fc-label-color);
        }
    }

    & .picker-label {
        font-weight: 500;
        font-size: 14px;
        line-height: 110.00000000000001%;
        letter-spacing: -0.28px;
        vertical-align: middle;
        color: var(--fc-contrast);
    }
}

.ant-picker.model-datepicker {
    width: 100%;
    height: 32px;
    background-color: var(--bg-datapicker-color) !important;
    border-radius: 24px;
    border: none !important;

    & input {
        opacity: var(--opacity-50, 1);
        color: var(--fc-contrast);
    }

    & svg path {
        fill: var(--fc-contrast);
    }
}

.model-container-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.4px;
    color: var(--fc-contrast);
    padding-inline: 24px;
    margin: 0;
}

.model-uploud {
    & svg {
        width: 17px;
        height: 17px;

        & path {
            fill: var(--fc-prim-color);
        }
    }
}

.primitives-tabs-wrapper {
    width: 100%;
    padding: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 24px;
}

.primitives-tabs-item {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.4px;
    opacity: var(--opacity-35, 1);
    color: var(--fc-contrast);
    cursor: pointer;

    &.active {
        opacity: 1;
        color: var(--fc-contrast);

    }
}

.primitives-title-value.cell {
    color: var(--fc-contrast);
}

.primitives-clear-btn {
    background-color: transparent;
    border: 1px solid var(--border-color);
    color: var(--fc-secondary);
    border-radius: 24px;
    padding-inline: 12px;

    &:hover {
        background-color: transparent !important;
        border: 1px solid var(--border-hover-color);
        color: var(--fc-secondary);
    }
}

.label-title-value.contrast span {
    color: var(--fc-contrast) !important;
}

.model-operation {
    & svg path {
        fill: var(--fc-prim-color);
    }
}

.primitives-title-value div,
.primitives-heaer-value div {
    font-weight: 500;
    font-size: 14px;
    line-height: 110.00000000000001%;
    letter-spacing: -0.238px;

    color: var(--fc-contrast);
}

.primitives-title-class.contrast {
    color: var(--fc-contrast);
}