.account-related-wrapper {
    width: calc(100% - 450px);

    &>.related-header-row {
        width: 100%;
        height: 80px;
        padding-inline: 24px;
        justify-content: space-between;
        align-items: center;
    }

    & .account-related-title {
        margin: 0;
        width: 50%;

        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -0.8px;
        color: var(--fc-contrast);
    }

    & .operation {
        & svg path {
            fill: var(--fc-prim-color);
        }
    }

    & .related-table-text,
    & tr>td {
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 110.00000000000001% !important;
        letter-spacing: -0.28px !important;
        color: var(--fc-contrast) !important;
    }

    & .ant-checkbox-wrapper span {
        font-weight: 500 !important;
        font-size: 14px !important;
        line-height: 110.00000000000001% !important;
        letter-spacing: -0.28px !important;
        color: var(--fc-contrast) !important;
    }
}