.onboarding-header {
  font-weight: 600;
  font-size: 35px;
  line-height: 48px;
  letter-spacing: 0.2px;
  margin-bottom: 82px;
}

.onboarding-container {
  width: 480px;
  //   background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px;
}

.onboarding-title {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  letter-spacing: 0.2px;
  margin-top: 16px;
  margin-bottom: 12px;
}

.onboarding-subtitle {
  font-weight: normal;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.2px;
  color: #626363;
  margin-bottom: 24px;
}

.checkbox-text {
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2px;
  color: #626363;
  margin-left: 15px;
}

.input-container {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;

  & .ant-input {
    margin-top: 0;
  }

  & input+svg {
    margin-left: 16px;
  }

  & span+svg {
    margin-left: 5px;
  }

  & label {
    margin-bottom: 0;
  }

  & .ant-radio-inner::after {
    background-color: #799aa9;
  }

  & .ant-radio-checked::after {
    border: 1px solid #799aa9;
  }

  & .ant-radio-checked .ant-radio-inner {
    border-color: #799aa9;
  }
}

.ant-form-item input[type='radio']+.ant-radio-inner {
  border-radius: unset;
}

.bankId {
  &-wrapper {
    width: 100%;
    padding: 0 30px;
  }

  &-header {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: #626363;
  }

  &-button {
    width: 75px;
    height: 45px;
    background-color: #183e4f;
    background-image: url('./img/BankID_logo_neg.png');
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 10px;
    cursor: pointer;

    &-wrapper {
      width: 100%;
      text-align: center;

      &>.bankId-button-login {
        margin: 16px auto;
      }
    }

    &-login {
      width: 120px;
      color: white;
      padding-left: 35px;
      font-weight: 700;
    }
  }

  &-or {
    width: 100%;
    text-align: center;
  }
}

.bankId-logo {
  width: 100px;
  height: 100px;
  background-color: #ffffff;
  background-image: url('./img/BankID_logo_black.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin: 0 auto;

  &-wrapper {
    width: 100%;
    text-align: center;
  }
}

.modal-bankId {
  &-header {
    text-align: center;
    width: 380px;
    margin: 20px auto 40px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.24px;
    text-align: center;
    color: var(--bankId-header-color);
    opacity: var(--bankId-header-opacity);
  }

  &-link {
    display: flex;
    margin-top: 20px;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px;
    width: 100%;

    &>a,
    &>a:active,
    &>a:visited {
      color: var(--fc-secondary);
    }

    & svg>g>path {
      fill: var(--login-forgot-arrow, #0f0f0f);
    }

    &>button {
      width: 100px;
      height: 40px;
      border-radius: 16px;
      cursor: pointer;
      margin-bottom: 24px;
    }
  }

  &-code-wrapper {
    width: 236px;
    height: 236px;
    margin: 0 auto;
    text-align: center;
    padding: 18px;
    display: flex;
    align-items: center;
    justify-content: center;

    &.white {
      background-color: #ffffff;
    }
  }

  &-text {
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    letter-spacing: 0.2px;
    color: #626363;
    width: 360px;
    margin: 0 auto;
  }

  &-cancel {
    height: 40px;
    padding: 11px 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Aeonik Quant;
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    color: #0f0f0f;
    cursor: pointer;
    border: 1px solid #0f0f0f;
    border-radius: 24px;
    margin: 0 auto;

    &.w360 {
      width: 360px;
    }

    &.w106 {
      width: 106px;
    }
  }
}

.number-is2fa-warning {
  font-family: Aeonik Quant;
  font-size: 20px;
  font-weight: 500;
  line-height: 23.2px;
  text-align: left;
  color: #0f0f0f;
  padding: 0 24px;
}

.modal-2fa-wrapper {
  padding: 25px 20px;
}

.modal-twofactor-title {
  font-family: Aeonik Quant;
  font-size: 24px;
  font-weight: 700;
  line-height: 27.84px;
  text-align: center;
  color: #0f0f0f;
}

// new

.profile-wrap.onboarding-wrap {
  height: 100vh;
}

.onbording-card-wrapper {
  display: flex;
  justify-content: flex-start;
  min-height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  background-color: var(--bg-main-color);
}

.onbording-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &.card-left {
    width: 450px;
    padding-top: 80px;
    position: relative;
    overflow-y: auto;
  }

  &.card-right {
    position: relative;
    width: calc(100% - 450px);
    padding-top: 80px;
    background-image: url('./img/right-side-bcg.svg');
    background-repeat: no-repeat;
    background-size: cover;
    overflow: hidden;

    // &::before {
    //     content: '';
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     bottom: 0;
    //     width: 1px;
    //     background: linear-gradient(0deg, #E6E6E6 0%, #808080 50%, #E6E6E6 100%);
    // }
  }
}

.onbording-card-number {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #ffab00;
  font-size: 38px;
  font-weight: 700;
  line-height: 44.08px;
  letter-spacing: 0.04em;
  text-align: center;
}

.onbording-step-wrapper {
  width: 100%;
}

.onbording-card-title {
  font-weight: 500;
  font-size: 40px;
  line-height: 40px;
  letter-spacing: -0.8px;
  text-align: left;
  color: var(--fc-onboarding-title);
  margin-top: 88px;
}

.onbording-card-description {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: -0.4px;

  text-align: left;
  color: var(--fc-onboarding-suybtitle);
  margin-top: 8px;
}

.onbording-card-header {
  font-weight: 500;
  font-size: 40px;
  line-height: 40px;
  letter-spacing: -0.8px;
  text-align: center;
  color: var(--fc-contrast);
  margin: 0;

  &.complet-step {
    margin-top: 0;
    margin-bottom: 40px;
  }

  &.category-title {
    margin-top: 72px;
    margin-bottom: 12px;
  }
}

.onbording-card-question {
  font-weight: 500;
  font-size: 24px;
  line-height: 120%;
  letter-spacing: -0.48px;
  text-align: center;
  color: var(--fc-label-color);
  max-width: 842px;
  margin: 24px auto 0 auto;
}

.onbording-card-subheader {
  font-size: 16px;
  font-weight: 400;
  line-height: 18.56px;
  text-align: left;
  color: #0f0f0f;
  margin-top: 24px;
  margin-bottom: 40px;

  &.step-two {
    margin-bottom: 24px;
    font-weight: 500;
    font-size: 16px;
    line-height: 100%;
    letter-spacing: 0;
    text-align: center;
    opacity: var(--opacity-40, 1);
    color: var(--fc-contrast);
  }
}

#onboarding-form-first {
  width: 666px;
  padding: 48px;
  margin: 0 auto;

  & .ant-space-vertical>.ant-space-item+div {
    margin-top: 16px;
  }
}

#onboarding-form-first .ant-radio-wrapper {
  display: flex;
  align-items: center;

  & svg {
    margin-left: 10px;
  }

  & .ant-radio+span {
    display: flex;
    align-items: center;
  }
}

.onbording-card-checkbox-wrap {
  margin-bottom: 48px;

  & .checkbox-block .ant-space {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &.ant-space-item {
      flex-basis: 33%;
    }
  }
}

.input-personal-number {
  margin-bottom: 40px;
}

.start-onbording-btn {
  width: 100%;

  &>span {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.8px;
    color: var(--fc-contrast);
  }
}

.date-picker-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  width: 200px;
  height: 40px;
  border: 1px solid #0f0f0f;
  border-radius: 24px;
  margin: 0 auto 40px auto;
  cursor: pointer;
}

#onboarding-form-first .ant-picker-input>input,
.onbording-card.card-right .ant-picker-input>input {
  padding-left: 16px;
  font-size: 16px;
  font-weight: 500;
}

.onbording-card-subtitle {
  font-size: 16px;
  font-weight: 400;
  line-height: 18.56px;
  text-align: left;
  color: var(--fc-contrast);
  margin-bottom: 24px;
}

.onbording-card-meeting-link {
  position: relative;
  display: flex;
  align-items: center;
  gap: 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 12px;
  letter-spacing: 0.32px;
  color: var(--fc-contrast);
  text-align: left;
  margin: 0;
  margin-bottom: 16px;

  &>a,
  &>a:active,
  &>a:visited {
    color: var(--fc-contrast);
  }

}

.onbording-card-copied {
  color: #ffab00;
}

button.optimise-btn.start-script-btn {
  box-sizing: border-box;
  margin-left: 4px;
  width: 360px;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  background-color: #0f0f0f;

  & span {
    color: #fcfcfc;
  }
}

.card-right-wrap {
  width: 570px;
  padding-right: 6px;
  overflow-y: auto;
  margin: 0 auto;
}

.onboarding-processing-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.step-signing {
  max-width: 434px;
  width: 100%;
}

.onboarding-category-wrap {
  margin-top: 24px;
  background-color: var(--bg-dropdown-color);
}

.finplan-datepicker.onboarding-datepicker {
  width: 360px;
}

#onboarding-form {
  padding: 0;

  & .finplan-datepicker.onboarding-datepicker input {
    padding-left: 14px;
    padding-right: 14px;
  }

  & label.ant-radio-wrapper.custom-radio-btn {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    gap: 8px;

    &>.ant-radio {
      flex-shrink: 0;
    }
  }
}

.card-right-wrap {
  scrollbar-width: thin;
  scrollbar-color: #8888888a #f1f1f1;
}

::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

::-webkit-scrollbar-track {
  background-color: #393332;
}

::-webkit-scrollbar-thumb {
  background-color: #7e716d;
  border-radius: 1.5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #808080;
}

.onboarding-category-disabled {
  cursor: not-allowed;
}

.onboarding-category-item {
  cursor: pointer;
}

.onboarding-question-wrap {
  width: 100%;
  padding: 24px;
  margin-bottom: 16px;
  color: #fcfcfc;
  background-color: var(--border-dropdown-color);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;

  font-weight: 500;
  font-size: 16px;
  line-height: 100%;
  letter-spacing: 0;


  &>ul {
    margin: 0;
    padding-left: 24px;
  }
}

.onboarding-question-other {
  width: 385px;
  padding: 24px;
  margin-left: auto;
  margin-bottom: 16px;
  color: #0f0f0f;
  background-color: var(--fc-label-color);
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
}

.onboarding-script-question {
  width: 434px;
  padding: 48px 6px 0 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0 auto;
  overflow-y: auto;
}

.next-btn-wrapper {
  width: 100%;
  padding-top: 16px;
  padding-bottom: 32px;

  s & .start-onbording-btn {
    margin: 0 auto;
  }
}

.onboarding-form-body {
  width: 360px;

  & .ant-form-item {
    margin-bottom: 24px;
  }
}

.checkbox-block {
  width: 100%;

  &>.ant-space>.ant-space-item {
    margin-top: 24px;
  }
}

.ondoarding-space-wrap {
  padding-left: 24px;
}

.onboarding-upload-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.onboarding-doc_number-wrap {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 16px;

  & .doc_expiry_date,
  & .doc_number {
    flex-basis: 50%;

  }

  & .ant-form-item.custom-datepicker-wrap>.ant-row.ant-form-item-row {
    width: 100%;
  }
}

.onboarding-upload-title {
  align-self: flex-start;
  padding-left: 24px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16.24px;
  text-align: left;
  color: var(--fc-label-color);
  margin: 0;
}

.onboarding-upload-btn {
  width: 360px;
  height: 40px;
  border: 1px solid var(--border-btn-color);
  border-radius: 24px;
  background-color: var(--border-dropdown-color);
  margin-top: 10px;
  margin-bottom: 5px;

  & span {
    color: var(--fc-contrast);
  }
}

.image-upload-wrapper.onboarding-upload {
  margin: 0;
  margin-bottom: 24px;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0;
}

.onboarding-upload-filename {
  margin: 0;
  width: 100%;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 18.56px;
  color: #4d4d4d;
}

.onbording-check-wrap {
  display: block;
  width: 360px;
  padding-left: 24px;
}

.onbording-check-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.24px;
  text-align: left;
  color: var(--fc-label-color);
}

.onboarding-upload-btn {
  &>span {
    font-size: 16px;
    font-weight: 500;
    line-height: 18px;
    text-align: left;
  }
}

.onboarding-question-title {
  font-size: 14px;
  font-weight: 500;
  line-height: 100%;
  text-align: left;
  color: var(--fc-btn-color);
  margin: 0;
}

.onboarding-question-sub {
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
  color: var(--fc-contrast);
  margin: 0;
  margin-top: 16px;
}

#onboarding-form .custom-required label {
  display: inline-block;
}

.card-right-wrap.onboarding-risk-profile {
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.types-label {
  &-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
  }

  &-number {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    border-radius: 50%;
    color: var(--fc-label-color);
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    background: linear-gradient(180deg, rgba(251, 123, 51, 0.3) 39%, rgba(255, 159, 238, 0.3) 100%);

  }

  &-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 23.2px;
    color: var(--fc-label-color);
    margin: 0;
  }
}

.ant-collapse-item-active .types-label-number {
  color: var(--fc-contrast);
}

.ant-collapse-item-active .types-label-title {
  color: var(--fc-contrast);
}

#onboarding-form .ant-collapse-expand-icon svg path {
  fill: var(--fc-label-color);
}

.diff-items-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 10px;
}

.diff-items {
  flex-basis: 50%;
}

#onboarding-form {
  & .custom-checkbox .ant-checkbox {
    border-color: var(--fc-label-color);
  }
}

.onboarding-label {
  font-size: 14px;
  font-weight: 500;
  line-height: 16.24px;
  text-align: left;
  color: #0f0f0f;
}

.recommended-counter {
  &-wrap {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    margin: 0;
    margin-bottom: 24px;
  }

  &-title {
    width: 100%;
    font-size: 20px;
    font-weight: 500;
    line-height: 23.2px;
    text-align: left;
    color: #0f0f0f;
  }

  &-number {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    flex-shrink: 0;
    background: #fb7b34;
    font-size: 14px;
    font-weight: 500;
    line-height: 16.24px;
    text-align: center;
    color: #0f0f0f;
  }
}

.recommended-card {
  &-wrapper {
    padding: 16px 24px;
    border: 2px solid #0f0f0f;
    border-radius: 12px;
    position: relative;
    margin-bottom: 16px;
  }

  &-title {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    text-align: left;
    color: #0f0f0f;
    margin: 0;
    margin-bottom: 4px;
    padding-right: 20px;
  }

  &-charge {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.24px;
    text-align: left;
    color: #4d4d4d;
    margin: 0;
    margin-bottom: 24px;
  }

  &-amount {
    font-size: 28px;
    font-weight: 500;
    line-height: 32.48px;
    letter-spacing: 0.04em;
    text-align: left;
    color: #0f0f0f;
    margin: 0;
  }

  &-delete {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }
}

.recommended-divider {
  margin: 24px 0;
  border-top: none;
  height: 1px;
  background: linear-gradient(90deg, #e6e6e6 0%, #808080 50%, #e6e6e6 100%);
}

#onboarding-form {
  & .ant-checkbox-wrapper {
    &>span.ant-checkbox+span {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.24px;
      text-align: left;
      color: #0f0f0f;
    }
  }

  & .ant-radio-wrapper {
    &>span.ant-radio+span {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.24px;
      text-align: left;
      color: var(--fc-label-color);
    }
  }
}

#onboarding-signing {
  & .ant-checkbox-wrapper {
    &>span.ant-checkbox+span {
      font-size: 14px;
      font-weight: 500;
      line-height: 16.24px;
      text-align: left;
      color: #0f0f0f;
    }
  }
}

.onboarding-category-wrap {
  & div.category-checkbx {
    background-color: var(--border-dropdown-color);
  }

  & div.category-checkbx.full {
    background-color: var(--fc-prim-color);
    background-image: url('../../assets/check-icon.svg');
    background-position: center center;
    background-repeat: no-repeat;
  }
}

.audioplayer-wrapper {
  position: fixed;
  top: 15px;
  right: 230px;
  width: 850px;
  min-height: 40px;
  z-index: 1003;
}

.upload-documents-wrapper {
  margin-top: 12px;
  padding-left: 16px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  row-gap: 16px;
}

.name-upload-wrapper.upload-documents {
  width: 100%;
  justify-content: space-between;

  &>.name-uploaded {
    flex-grow: 1;
    text-align: left;
    cursor: pointer;
    margin: 0;
    color: var(--fc-contrast);
  }

  & svg path {
    fill: var(--fc-label-color)
  }
}

.modal-content-paper.documents-preview {
  & .detail-container {
    box-sizing: border-box;
  }

  &>div {
    height: 100%;

    &>.ant-modal-content {
      padding: 40px;
    }

    & .ant-modal-body {}
  }
}

.upload-document-img {
  width: 100%;
  height: auto;
}

.onbording-card-subtitle.offline-meeting {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  line-height: 18px;
  color: #4d4d4d;

  &.hour {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: #0f0f0f;
    margin-top: 11px;
  }
}

.optimise-btn.start-script-btn.record-start-btn {
  margin: 51px 0px;
}

.ant-divider {
  &.custom-divider {
    display: flex;
    align-items: center;
    text-align: center;
    color: #808080;
  }

  &.custom-divider::before,
  &.custom-divider::after {
    content: '';
    flex: 1;
    height: 1px;
    border-block-start: none;
    background: linear-gradient(90deg, #e6e6e6 0%, #808080 50%, #e6e6e6 100%);
  }

  &.custom-divider::before {
    margin-right: 8px;
  }

  &.custom-divider::after {
    margin-left: 8px;
  }
}

.reschedule-wrapper {
  display: flex;
  justify-content: flex-start;
  margin-top: 31px;

  &>.date-picker-btn {
    margin: 0;
  }
}

.card-right-wrap.scheduled-meeting {
  box-sizing: border-box;
  width: 360px;
  height: 60vh;
  padding: 0;
  min-height: 440px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.recorder-wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.recording-timer-wrapper {
  width: 100%;
  margin-top: 31px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.recording-timer-box {
  height: 24px;
  width: fit-content;
  border-radius: 8px;
  padding: 2px 8px;

  display: flex;
  align-items: center;
  gap: 6px;

  background: #fb7b3433;
}

.recording-timer {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
  color: var(--fc-prim-color);
}

.card-right-wrap.scheduled-meeting.script {
  flex-direction: column;
  align-items: flex-start;
}

.onbording-card-header.complet-step.recorder {
  text-align: center;
}

button.optimise-btn.start-script-btn.recorder {
  width: 100%;
  margin: 0;
}

.player-btn {
  cursor: pointer;
}

.recorder-wrapper.mini {
  width: 360px;
  padding: 14px 28px;
  position: fixed;
  top: 10px;
  right: 410px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  border: 2px solid #fb7b34;
  border-radius: 13px;
  background-color: var(--bg-dropdown-color);
  z-index: 1700;

  & .recording-timer-wrapper {
    margin: 0;
    margin-bottom: 16px;
    width: 100%;

    &>.recording-timer-box {
      margin: 0;
    }
  }

  & .player-btn-mini-wrap {
    display: flex;
    flex-wrap: nowrap;
    gap: 24px;
  }
}

.microfone-zone {
  flex-basis: 40%;
}

.button-zone {
  flex-basis: 60%;
}

.back-to-record-btn {
  position: fixed;
  top: 12px;
  right: 200px;
  z-index: 1700;
}

.risk-warning-wrapper {
  width: 100%;
  margin-top: 24px;

  font-weight: 400;
  font-size: 14px;
  line-height: 100%;
  letter-spacing: 0;
  color: var(--fc-label-color);
  text-align: justify;
}

.modal-reschedule-text {
  margin: 14px 0;
}

.modal-advisor-name.modal-reschedule {
  margin: 14px 0;
}

button.ant-btn.onbording-collapse-btn {
  position: absolute;
  top: 104px;
  right: 24px;

  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 1px solid var(--border-dropdown-color);
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    border: 1px solid var(--border-color);
  }
}

.left-footer {
  width: 100%;
  height: 72px;
  padding: 24px 32px;
}

.left-footer-link {
  font-family: Aeonik Quant;
  font-weight: 500;
  font-size: 14px;
  line-height: 110.00000000000001%;
  letter-spacing: -2%;
  opacity: var(--opacity-35, 1);
  color: var(--fc-contrast);
}

.onbording-card-fill {
  height: 100%;
  padding-inline: 24px;

  background-image: url('./img/left-side-bcg.svg');
  background-repeat: no-repeat;
  background-size: cover;

  &.script {
    height: auto;
  }
}

.onbording-datepicker-wrap {
  display: flex;
  flex-wrap: wrap;

  &>p.step-two {
    width: 100%;
  }

  &>.custom-datepicker-wrap {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  & .ant-form-item.custom-datepicker-wrap>.ant-row.ant-form-item-row {
    width: 212px;

    & span.ant-picker-suffix span.anticon>svg>path {
      fill: var(--fc-prim-color) !important;
    }
  }
}

.onboarding-del-btn {
  & span {
    color: var(--fc-contrast);
  }

  & span.ant-btn-icon svg path {
    fill: var(--fc-prim-color);
  }
}

.onbording-meeting-card-wrapper {
  width: 500px;
  margin: 0 auto;
  margin-top: 24px;
  padding: 40px;
  border: 1.5px solid #ffffff1f;
  border-radius: 24px;
  background: linear-gradient(109.49deg,
      rgba(255, 255, 255, 0.4) 13.07%,
      rgba(255, 255, 255, 0.16) 86.98%);

  & .onbording-meeting-card-title {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.48px;
    color: var(--fc-secondary);
    margin: 0;
    margin-bottom: 8px;
    background: transparent;
  }

  & .onbording-meeting-card-date {
    font-weight: 500;
    font-size: 32px;
    line-height: 32px;
    letter-spacing: -0.64px;
    color: var(--fc-secondary);
    margin: 0;
    background: transparent;
  }

  & .onbording-meeting-card-hour {
    font-weight: 500;
    font-size: 40px;
    line-height: 40px;
    letter-spacing: -0.8px;
    color: var(--fc-secondary);
    margin: 0;
    background: transparent;
  }

  & .onbording-record-img {
    width: 312px;
    height: 312px;
    margin: 0 auto;
    background-image: url('./img/record-img.svg');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }

  & .date-picker-btn.reschedule {
    background-color: transparent;
    width: 132px;
    height: 32px;
    cursor: pointer;
    margin: 0;

    & input,
    & input::placeholder {
      color: var(--fc-secondary);
      padding-inline: 0;
    }

    & svg path {
      fill: var(--fc-prim-color);
    }
  }

  &.online {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 500px;

    & .card-footer-wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & .custom-datepicker-wrap {
        flex-basis: 50%;
      }

      & .custom-btn-wrap {
        flex-basis: 50%;
      }
    }

    & .onbording-card-meeting-link {
      cursor: pointer;

      & svg path {
        fill: var(--fc-prim-color);
      }
    }
  }
}

.onboarding-script-btn {
  width: 91px;
  height: 32px;
  border: 1px solid var(--border-btn-color);
  border-radius: 24px;
  background: transparent;
  cursor: pointer;

  &:hover {
    background: transparent !important;
  }

  & span {
    color: var(--fc-contrast);
  }
}

.onbording-player-status {
  font-weight: 500;
  font-size: 32px;
  line-height: 32px;
  letter-spacing: -0.64px;
  color: var(--fc-contrast);
  margin: 0;
}

.category-item .table-date-progress {
  position: absolute;
  bottom: -7px;
  left: 0;
}

.onboarding-textarea-purpose {
  width: 100%;
}

.m-top-12 {
  margin-top: 12px;
}

#onboarding-form .form-phone {
  &+svg {
    cursor: pointer;
    position: absolute;
    top: 30px;
    right: 10px;

    & path {
      fill: var(--fc-prim-color);
    }
  }

  & .phone-number-wrapper {
    border-color: var(--border-dropdown-color);

    & .select-phonecode {
      background-color: var(--bg-main-color);
      color: var(--fc-contrast);
    }

    & .ant-select-selection-search>input {
      color: var(--fc-contrast);
    }

    & .ant-input {
      background-color: var(--bg-main-color);
      color: var(--fc-contrast);
    }
  }
}

#onboarding-form {
  & .ant-form-item.custom-datepicker-wrap {
    height: auto;
    margin-bottom: 24px;
  }

  & .ant-checkbox-wrapper>span.ant-checkbox+span {
    color: var(--fc-label-color);
  }

  & input {
    &::placeholder {
      color: var(--fc-label-color);
    }
  }

  & textarea {
    &::placeholder {
      color: var(--fc-label-color) !important;
    }
  }
}

.onboarding-custom-inputnumber-dropdown.ant-select-dropdown .ant-select-item.ant-select-item-option {
  background-color: var(--bg-main-color);
}

.card-right-wrap.risk-profile {
  width: 706px;
}

.risk-level-item-wrapper {
  display: flex;
  align-items: center;
  width: 700px;
  height: 48px;
  cursor: pointer;

  &>.risk-level-header {
    flex-basis: 309px;
    height: 100%;

    display: flex;
    align-items: center;
    padding-left: 40px;

    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 0.12px;
    text-transform: uppercase;

    opacity: var(--opacity-50, 1);
    color: var(--fc-contrast);
  }

  &>.risk-level-mrm-header {
    width: 82px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 10%;
    text-transform: uppercase;

    opacity: var(--opacity-50, 1);
    color: var(--fc-contrast);
  }

  &>.risk-level-volatility-header {
    flex-basis: 309px;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 10%;
    text-transform: uppercase;

    opacity: var(--opacity-50, 1);
    color: var(--fc-contrast);

  }

  & .check-round {
    position: absolute;
    top: 6px;
    left: 5px;
    display: none;
  }

  &.selected {
    & .check-round {
      display: block;
    }

    &>.risk-level,
    &>.risk-level-volatility {
      position: relative;
      background-color: var(--border-dropdown-color);
    }

  }

  &:hover {

    &>.risk-level,
    &>.risk-level-volatility {
      background-color: var(--border-dropdown-color-light);
    }
  }

  &.recommended {
    &>.risk-level>.title-recomended {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }


  &>.risk-level {
    flex-basis: 309px;
    height: 100%;

    display: flex;
    align-items: center;

    padding-left: 40px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0, 32px;
    color: var(--fc-contrast);

    & .title-recomended {
      display: none;

      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0;
      color: var(--col, #6367C3);

      background: #6367C31F;
      border-radius: 8px;

      padding-inline: 8px;
      margin: 0;
    }
  }

  &>.risk-level-mrm {
    width: 82px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--fc-contrast);
  }

  &>.risk-level-volatility {
    flex-basis: 309px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.32px;
    color: var(--fc-contrast);
  }

}

.risk-home-bias-wrap {
  width: 276px;
  margin: 0 auto;
  margin-top: 24px;
  margin-bottom: 47px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex-wrap: wrap;

  &>.risk-home-bias-title {
    height: 47.5px;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    letter-spacing: 10%;
    text-transform: uppercase;

    opacity: var(--opacity-50, 1);
    color: var(--fc-contrast);
  }

  &>.risk-home-bias-value {
    height: 47.5px;

    display: flex;
    justify-content: center;
    align-items: center;

    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.32px;
    color: var(--fc-secondary);
    background-color: var(--border-dropdown-color);
  }
}