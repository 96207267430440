.chart-header-wrapper {
    height: 72px;
    width: 100%;
    border: 1px solid var(--border-color);
    display: flex;
    flex-wrap: nowrap;

    &>.chart-header-cell {
        width: 30%;
        display: flex;

        &>.chart-header-cell-title {
            display: flex;
            align-items: center;
            padding-left: 24px;
            width: 50%;
            font-weight: 700;
            font-size: 12px;
            line-height: 14px;
            letter-spacing: 0.6px;
            text-transform: uppercase;
            color: var(--fc-secondary);
        }

        &>.chart-header-cell-data {
            width: 50%;
            display: flex;
            flex-wrap: wrap;
            align-items: stretch;
            gap: 0;
            font-weight: 500;
            font-size: 24px;
            line-height: 24px;
            letter-spacing: -0.48px;
            color: var(--fc-contrast);

            &>div {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-right: 24px;
                padding-left: 6px;
            }
        }
    }
}