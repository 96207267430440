.modal-invate-client {
    & .sign-up-form-wrapper {
        justify-content: center;
    }
}

.new-client-card {
    box-sizing: border-box;
    width: 100%;
    height: 180px;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 16px;
    border: 1px solid;

    padding: 24px 16px;
    background:
        center / cover no-repeat url('./img/new-client-bcg.svg'),
        linear-gradient(138.43deg, #FF9FEE 4.7%, #6467C3 52.57%);
}

.invate-client-form {
    width: 100%;
    margin-top: 24px;
    padding: 16px 0;
}