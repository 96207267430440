body.dark-theme {
  --modal-bcg: #2d2624;
  --modal-title-color: #ffffff;
  --temp-text-color: #FFFFFF;
  --temp-text-bcg: #251F1D;
}

body.light-theme {
  --modal-bcg: #ffffff;
  --modal-title-color: #000000;
  --temp-text-color: #000000;
  --temp-text-bcg: #FFFFFF;
}

.modal-portfolio {
  background-color: var(--modal-bcg);
  padding: 24px;
  border-radius: 16px;

  & .ant-modal-content {
    background-color: var(--modal-bcg);
    box-shadow: none;
    padding: 0;

  }
}

.modal-newportfolio-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: var(--modal-title-color);
}

.color-box-header {
  box-sizing: border-box;
  margin-top: 24px;
  margin-bottom: 24px;
  width: 100%;
  height: 180px;
  padding: 16px;
  border: 1px solid;
  border-radius: 16px;
  background-color: #393332;
  background-image: url('../../assets/new-portfolio-card.svg');
  border-image-source: linear-gradient(180deg, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0.066) 100%);
  display: flex;
  align-items: end;
  flex-wrap: wrap;

}

.download-import-temp {
  height: 22px;
  display: inline-flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  gap: 2px;

  border-radius: 24px;
  padding: 5px 6px;
  background-color: var(--temp-text-bcg);
  border: 1px solid #2B2726;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--temp-text-color);

  &>svg>path {
    fill: #FB7B34;
  }
}

.uploaded-name {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  margin-bottom: 16px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: center;
  color: var(--temp-text-color);

  &>svg>path {
    fill: #FB7B34
  }
}

input.custom-input.full-width {
  width: 100% !important;
  background-color: var(--bg-main-color);
  border: 1px solid var(--border-color);
  color: var(--fc-secondary);

  &:hover,
  &:focus {
    border: 1px solid var(--border-hover-color);
    background-color: var(--bg-main-color);
  }
}

.wrapper-portfolio-number {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  &>.portfolio-number {
    flex-basis: 80%;
  }

  &>.portfolio-number.portfolio-info {
    flex-basis: 75%;
    max-width: 282px;
  }

  &>.portfolio-number-sync {
    flex-basis: 20%;
    display: flex;
    align-self: stretch;
    padding-bottom: 16px;

    &>.ant-form-item {
      margin: 0;
      display: flex;
      align-items: center;
    }

    & .ant-col.ant-form-item-label {
      text-align: center;
    }
  }

  &>.portfolio-number-sync.portfolio-info {
    flex-basis: 25%;
    display: flex;
    align-self: stretch;
    padding-bottom: 0px;

    &>.ant-form-item {
      margin: 0;
      display: flex;
      align-items: center;
    }

    & .ant-col.ant-form-item-label {
      text-align: center;
    }
  }
}

.wrapper-portfolio-tax {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  &>.portfolio-taxation {
    flex-basis: 80%;
  }

  &>.portfolio-taxation.portfolio-info {
    flex-basis: 75%;
  }

  &>.portfolio-taxrate {
    flex-basis: 20%;

    &>.ant-form-item {
      margin: 0;
      display: flex;
      align-items: center;
    }

  }

  &>.portfolio-taxrate.portfolio-info {
    flex-basis: 25%;
    min-width: 120px;

    &>.ant-form-item {
      margin: 0;
      display: flex;
      align-items: center;
    }

  }
}

.modal-newportfolio-button-wrapper {
  justify-content: flex-end;
  gap: 1px;

  &>.modal-action-btn.cancel-btn {
    border: 1px solid transparent;

    &>span {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: var(--fc-secondary);
    }

    &:hover {
      border: 1px solid var(--border-hover-color);
    }
  }

  &>.modal-action-btn.save-btn {
    padding: 0 12px;
    border: none;
    background-color: #6467C3;
    border: 1px solid #6467C3;

    &>span {
      font-weight: 500;
      font-size: 16px;
      line-height: 16px;
      color: var(--fc-secondary);
    }

    &:hover {
      background-color: #6467C3 !important;
    }
  }
}

.portfolio-info-wrapper {
  & .custom-inputnumber {
    &.ant-input-number {
      border: 1px solid var(--border-hover-color);

      &:hover {
        border: 1px solid var(--border-color-hover);
      }

    }
  }

  & .ant-select.ant-select-outlined.custom-select {
    border: 1px solid var(--border-hover-color);

    &:hover {
      border: 1px solid var(--border-color-hover);
    }
  }

  & input.custom-input.custom-input.full-width {
    border: 1px solid var(--border-hover-color);

    &:hover,
    &:focus {
      border: 1px solid var(--border-color-hover);
    }
  }
}