.modal-content-paper {
    height: 375px;
    border-radius: 13px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    &>div {
        width: 100%;
    }

    & .ant-modal-content {
        background-color: var(--bg-dropdown-color);
        padding: 32px 24px;
    }

    & .modal-title {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        letter-spacing: -2%;

        color: var(--fc-contrast);
        margin-top: 0;
    }

    & .modal-description {
        font-weight: 400;
        font-size: 14px;
        line-height: 16.24px;
        letter-spacing: 0;
        color: var(--fc-label-color);
    }

    & .modal-name {
        font-weight: 500;
        font-size: 14px;
        line-height: 15.4px;
        letter-spacing: -2%;
        color: var(--fc-contrast);
    }

    & .modal-divider {
        background-color: var(--border-hover-color);
    }

    & .modal-action-btn.cancel-btn {
        border: 1px solid transparent;
        background: transparent;
        padding: 0 12px;
        height: 32px;

        &>span {
            font-weight: 500;
            font-size: 16px;
            line-height: 16px;
            color: var(--fc-secondary);
        }

        &:hover {
            background: transparent;
            border: 1px solid var(--border-hover-color);
        }
    }

    & .modal-action-btn.save-btn {
        padding: 0 12px;
        height: 32px;
    }
}

.modal-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 18.56px;
    text-align: center;
    margin: 0;
}

.modal-button-wrapper {
    justify-content: center;
    gap: 16px;
    margin-top: 69px;
    margin-bottom: 72px;
}

.modal-action-btn {
    height: 32px;
    padding: 10px 32px;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    border-radius: 24px;
    border: 1px solid #0F0F0F;

    &.cancel-btn {
        color: #0F0F0F;
    }

    &.save-btn {
        color: #FCFCFC;
        background-color: #0F0F0F;

        &:hover {
            color: #FCFCFC !important;
            background-color: #0F0F0F !important;
            border: 1px solid #FB7B34 !important;

            &>span {
                color: #FCFCFC !important;
            }
        }
    }
}