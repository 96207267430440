.admin-tools-wrapper {
  width: 100%;
  padding: 0 20px;
  margin-top: 24px;
}

.instrument-form {
  padding: 0 24px;

  .instrument-form-block {
    box-sizing: border-box;
  }

  &-iner-wrapper {
    padding: 48px 24px;
  }
}

.instrument-search-wrapper {
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  top: 97px;
  right: 0;
  padding-right: 24px;
  z-index: 1602;
}

.instrument-table-wrapper {
  overflow-y: auto;
}