body.dark-theme {
    --bg-root-color: #000000;
    --bg-primary-color: #131111;
    --bg-secondary-color: #1B1918;
    --bg-main-color: #251F1D;
    --bg-select-color: #251F1D;
    --bg-select-disable-color: #393332;
    --bg-dropdown-color: #2D2624;
    --bg-datapicker-color: #393332;

    --fc-contrast: #FFFFFF;
    --fc-primary: #656464;
    --fc-secondary: #F2EEED;
    --fc-secondary-light: #8B8685;
    --fc-prim-color: #FB7B34;
    --fc-third-color: #827f7f;
    --fc-label-color: #7E716D;
    --fc-btn-color: #1B1918;
    --fc-header-color: #525252;
    --fc-input-color: #9C9998;
    --fc-optim-color: #6467C3;
    --fc-onboarding-title: #F6F4F3;
    --fc-onboarding-suybtitle: #978681;

    --logo-img-border-radius: 26px;
    --logo-img-opacity: 50%;

    --login-wrapper-padding: 18.5px 19.33px 19.4px;
    --login-btn-color: #131111;
    --login-btn-bgc: #FB7B33;
    --login-btn-bgc-hover: #FF9F53;
    --login-btn-border-hover: #5B5958;
    --login-label-fc: #555353;
    --login-placeholder-fc: #514E4D;
    --login-switch-border-color: #808080;
    // --login-switch-bgc: #FB7B34;
    --login-switch-handle-border: #FB7B34;
    --login-forgot-btn-border: 1px solid #808080;
    --login-forgot-btn-br: 24px;
    --login-forgot-arrow: #FB7B34;

    --bankId-header-color: #FCFCFC;
    --bankId-header-opacity: 50%;

    --opacity-25: 25%;

    --border-color: #2B2726;
    --border-btn-color: #393432;
    --border-hover-color: #423A38;
    --border-dropdown-color: #423A38;
    --border-dropdown-color-light: #504644;
    --border-button-color: #3A333166;
    // New

    --root-theme-bcg: #251F1D;
    --btn-transparent: transparent;
    --step-btn-color: transparent;
    --opacity-35: 0.35;
    --opacity-40: 0.40;
    --opacity-60: 0.6;
    --opacity-50: 0.5;

}

body.light-theme {
    --bg-root-color: #ffffff;
    --bg-primary-color: #ffffff;
    --bg-secondary-color: #ffffff;
    --bg-main-color: #ffffff;
    --bg-select-color: transparent;
    --bg-select-disable-color: #00000026;
    --bg-dropdown-color: #FCFCFC;
    --bg-datapicker-color: #FCFCFC;

    --fc-contrast: #000000;
    --fc-primary: #656464;
    --fc-secondary: #0F0F0F;
    --fc-secondary-light: #8B8685;
    --fc-prim-color: #FB7B34;
    --fc-third-color: #656464;
    --fc-label-color: #0F0F0F;
    --fc-btn-color: #ffffff;
    --fc-header-color: #0F0F0F;
    --fc-input-color: #FCFCFC;
    --fc-optim-color: #6467C3;
    --fc-onboarding-title: #0F0F0F;
    --fc-onboarding-suybtitle: #2e2d2d;

    --logo-img-border-radius: none;
    --logo-img-opacity: 100%;

    --login-wrapper-padding: 0;
    --login-btn-color: #FCFCFC;
    --login-btn-bgc: #0F0F0F;
    --login-btn-bgc-hover: #0F0F0F;
    --login-btn-border-hover: #FB7B34;
    --login-label-fc: #0F0F0F;
    --login-placeholder-fc: #4D4D4D;
    // --login-switch-border-color: #808080;
    --login-switch-bgc: #FB7B34;
    --login-switch-handle-border: #000000;
    // --login-forgot-btn-border: 1px solid #808080;
    --login-forgot-btn-br: 24px;
    // --login-forgot-arrow: #FB7B34;

    --bankId-header-color: #0F0F0F;
    --bankId-header-opacity: 100%;

    --border-color: #2B2726;
    --border-btn-color: #393432;
    --border-hover-color: #FB7B34;
    --border-dropdown-color: #0F0F0F;
    --border-button-color: #3A333166;
    // New

    --root-theme-bcg: #F6F7F9;
    --btn-transparent: #F6F7F9;
    --step-btn-color: #FCFCFC;

}