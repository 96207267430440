.modal-content-paper {
  height: 375px;
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &>div {
    width: 100%;
  }
}

.modal-title {
  font-family: 'Aeonik Quant';
  font-size: 24px;
  font-weight: 700;
  line-height: 27.84px;
  margin-top: 52px;
  text-align: center;
}

.modal-text-wrapper {
  margin-top: 56px;
}

.modal-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 110.00000000000001%;
  letter-spacing: -0.28px;
  margin: 0;
  color: var(--fc-label-color) !important;
}

.modal-text.modal-item-name.list {
  margin-left: 10px;
  margin-top: 8px;
}

.modal-button-wrapper {
  justify-content: center;
  gap: 16px;
  margin-top: 69px;
  margin-bottom: 72px;
}

.modal-action-btn {
  height: 32px;
  padding: 10px 32px;
  font-family: 'Aeonik Quant';
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  border-radius: 24px;
  border: 1px solid #0f0f0f;

  &.cancel-btn {
    color: #0f0f0f;
  }

  &.save-btn {
    color: #fcfcfc;
    background-color: #0f0f0f;

    &:hover {
      color: #fcfcfc !important;
      background-color: #0f0f0f !important;
      border: 1px solid #fb7b34 !important;

      &>span {
        color: #fcfcfc !important;
      }
    }
  }
}

.check {
  & .veryfication-card {
    box-sizing: border-box;
    width: 100%;
    height: 180px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    border-radius: 16px;
    border: 1px solid;

    padding: 24px 16px;
    background:
      center / cover no-repeat url('./img/error-bcg.svg'),
      linear-gradient(180deg, #FB7B33 39%, #BE3689 100%);

    &.successed {
      background:
        center / cover no-repeat url('./img/successed-bcg.svg'),
        linear-gradient(180deg, #81AB64 43.5%, #AAC17E 100%);
    }

    & .modal-result-short {
      font-weight: 500;
      font-size: 32px;
      line-height: 32px;
      letter-spacing: -0.64px;
      text-align: right;
      color: var(--fc-btn-color);
    }

    & .modal-result-footer {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0;
      text-align: right;
      color: var(--fc-label-color);
    }
  }

  & .modal-header-subtitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: -0.4px;
    color: var(--fc-label-color);
    margin: 0;
    margin-top: 24px;

    &-result {
      font-weight: 500;
      font-size: 20px;
      line-height: 24px;
      letter-spacing: -0.4px;
      color: var(--bankId-header-color);
      margin: 0;
      margin-bottom: 24px;
    }
  }

  & .modal-result-column {
    max-height: 300px;
    overflow-y: auto;
  }

  & ul {
    padding-inline-start: 12px;
    margin: 0;
  }

  & .modal-action-btn.return {
    border: 1px solid var(--border-dropdown-color);

    &:hover {
      border: 1px solid var(--border-dropdown-color-light);

    }
  }

  & .modal-action-btn.save-btn.maps {
    background-color: var(--fc-prim-color) !important;
    border: none;

  }
}