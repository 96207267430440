.portfolio-table {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  .ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td {
    border-right: none !important;
  }

  .ant-pagination-item-active {
    border-color: #305572;

    a {
      color: #305572;
    }
  }

  .current-portfolio-table-total-row {
    font-size: 14px;
    font-family: 'Lato';
    color: #ffffff;
    background: #305572 !important;

    &:hover {
      &>td {
        background-color: unset !important;
      }
    }

    input {
      display: none;
    }

    span {
      display: none;
    }
  }
}

.table-input {
  width: 70px;
  border: 1px solid #DDE9EA;
  border-radius: 5px;
  color: #8A8A8A;
}

.current-portfolio-graphics-row-container tr>td:last-child,
.current-portfolio-graphics-row-container tr>th:last-child {
  padding-right: 25px !important;
}

.current-portfolio-graphics-row-container tr>th,
.portfolio-table tr>th {
  font-weight: 600;
}

.assetclass-cell {
  &-asset {
    padding: 0 10px;
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    line-height: 19.12px;
    text-align: left;
    color: #232323;
  }

  &-category {
    padding: 0 10px;
    margin: 0;
    font-size: 12px;
    font-weight: 700;
    line-height: 16.39px;
    text-align: left;
    color: #7E8493;
  }
}

th.table-header-right>.ant-table-column-sorters {
  justify-content: flex-end;
}

th.table-header-center>.ant-table-column-sorters {
  justify-content: center;
}

.client.portfolio-table.sugg-portfolio {

  & td.ant-table-cell {
    height: 80px;
  }

  & .ant-table-cell.name {
    padding-left: 24px;
  }

  & .ant-table-cell.table-header-right.weight {
    color: var(--fc-prim-color);
  }
}