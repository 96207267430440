body.dark-theme {
    --account-btn-border-color: #423A38;
    --account-btn-text-color: #FFFFFF;
}

body.light-theme {
    --account-btn-border-color: #0F0F0F;
    --account-btn-text-color: #0F0F0F;
}

button.ant-btn.add-btn-whith-icon {
    height: 24px;
    padding: 0 9px;
    padding-top: 3px;
    background-color: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;
    border: 1px solid var(--account-btn-border-color);
    border-radius: 24px;

    &.light,
    &.ant-btn-variant-outlined.light:hover {
        background-color: #F2EEED;
        padding-top: 1px;

        &>span {
            color: #1B1918;
        }

        & span.ant-btn-icon {
            height: 14px;
        }
    }

    &>span {
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
        color: var(--account-btn-text-color);
    }

    &.ant-btn-variant-outlined:hover {
        background-color: transparent;
    }
}