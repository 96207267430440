.portfolio-tabs-btn {
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.02em;
    text-align: left;

    &>span {
        color: var(--fc-primary);
    }

    &.selected {
        &>span {
            color: var(--fc-contrast);
        }
    }

}

.client.export-btn {
    padding: 8px 12px;
    background-color: transparent;
    border-radius: 24px;
    border: 1px solid var(--border-hover-color);
    color: var(--fc-contrast);

    &:hover:not(:disabled):not(.ant-btn-disabled) {
        color: var(--fc-contrast);
        border: 1px solid var(--login-switch-border-color);

    }
}

.client.portfolio-table {
    & .ant-table.ant-table-middle {
        background-color: transparent;
    }

    & .ant-table-thead>tr>th {
        background-color: transparent;
        color: var(--fc-primary);
        border: none;
    }

    & .ant-table-placeholder {
        background-color: transparent !important;
    }

    & tr,
    & td,
    & th::before,
    & th {
        border-left: none !important;
        border-right: none !important;
        background-color: transparent !important;
    }

    & th {

        & span.ant-table-column-title,
        &.ant-table-cell {
            font-size: 12px;
            font-weight: 700;
            line-height: 12px;
            letter-spacing: 0.1em;
            text-align: left;
            text-transform: uppercase;
            color: var(--fc-primary);

        }

        &.holdings-instrument span.ant-table-column-title {
            color: var(--fc-contrast);
        }
    }

    & tr>td {
        font-size: 12px;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0.1em;
        text-align: left;
        color: var(--fc-contrast);

        &.holdings-instrument {
            font-size: 14px;
            font-weight: 500;
            line-height: 15.4px;
            letter-spacing: -0.02em;

        }

        &.holdings-fee>p {
            color: var(--fc-prim-color);

        }

        &>p.holdings-assetclass-cell-asset {
            font-size: 14px;
            font-weight: 500;
            line-height: 15.4px;
            letter-spacing: -0.02em;
            text-align: left;
            color: var(--fc-contrast);
        }
    }

    & .ant-table-tbody>tr.ant-table-row>td {
        border-bottom: 1px solid var(--border-hover-color) !important;
    }

}

.client.table-wrapper {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--border-color);
    position: relative;

    &>.table-date-row {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 24px;
    }

    & .table-date-name {
        margin: 0;
        font-size: 12px;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0.1em;
        text-align: left;
        color: var(--fc-contrast);
    }

    & .table-date-persent {
        margin: 0;
        margin-top: 4px;
        font-size: 12px;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0.1em;
        text-align: left;
        color: var(--fc-prim-color);

        &.opt {
            color: var(--fc-optim-color);
        }
    }

    & .table-date-value {
        font-size: 12px;
        font-weight: 700;
        line-height: 12px;
        letter-spacing: 0.1em;
        text-align: left;
        color: var(--fc-third-color);
    }

    & .table-date-progress {
        position: absolute;
        bottom: -7px;
        left: 0;
    }

}